import { LoyaltyInfo } from "@accor/ace-ui-core";
import { getUserData } from "~/components/common/my-account/v1/js/user";

export default class FairmontLoyaltyInfo extends CoreJS.BaseComponent {
  constructor(componentHost, componentName) {
    super(componentHost, componentName);
  }

  async initialize() {
    const userData = await getUserData();

    if (!userData) {
      return
    }

    this.componentHost.style.display = "block";

    this.hydrateComponentWithUserData(userData);
  }

  hydrateComponentWithUserData(userData) {
    const { status, loyaltyPoints, currentNightsBalance } = userData;
    const currentNightsBalanceElement = this.componentHost.querySelector('.noOfNightsValue');
    const loyaltyPointsElement = this.componentHost.querySelector('.noOfPointsValue');
    const loyaltyStatusElement = this.componentHost.querySelector('.productTierValue');
  
    currentNightsBalanceElement.innerHTML = currentNightsBalance;
    loyaltyPointsElement.innerHTML = loyaltyPoints;
    loyaltyStatusElement.innerHTML = status;
    loyaltyStatusElement.classList.add(status.toLowerCase());
  }
}

CoreJS.BaseComponent.registerComponent(
  LoyaltyInfo.CLASS_NAMESPACE,
  FairmontLoyaltyInfo,
  true,
);
