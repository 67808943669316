import { CalloutContainer } from "@accor/ace-ui-core";

/**
 * Some tabs have different IDs in the URL than in the HTML `data-filter-tab-id` attribute.
 * They can't be easily synced on the backend side so we have to map them here.
 */
const MISMATCHED_TABS = {
  CATERINGS: "RESTAURANTS",
};

export default class FairmontCalloutContainer extends CalloutContainer {
  constructor(componentHost) {
    super(componentHost);

    this.setAriaActiveTabFromURL();
  }

  initialize() {
    this.hasLoadMoreFeature =
      !!this.componentHost.querySelector("[data-callout-row]");

    if (this.hasLoadMoreFeature) {
      this.loadMoreBtn = this.componentHost.querySelector(
        ".ace-guest-viewmore",
      );
    }

    super.initialize();
  }

  guestviewmoreList() {
    if (!this.hasLoadMoreFeature) {
      super.guestviewmoreList();
      return;
    }

    this.loadMoreBtn.addEventListener("click", (event) => {
      event.stopImmediatePropagation();
      this.onLoadMoreButtonClick();
    });

    const hasTabs = this.componentHost.querySelector(".ace-callout-container-filters__tab-list");

    if (hasTabs) {
      // When there are tabs filters, the following logic of hidding items
      // at page load is already handled inside horizontalTabFilterRefresh
      return;
    }

    this.identifyNumberOfItems();

    const numberOfCalloutItemsToShow = this.numberOfItem;
    const calloutItems = this.componentHost.querySelectorAll(
      'li .ace-callout-component'
    );

    for (let i = numberOfCalloutItemsToShow; i < calloutItems.length; i++) {
      calloutItems[i].parentElement.hidden = true;
    }
  }

  onLoadMoreButtonClick() {
    const hiddenElements = this.componentHost.querySelectorAll(
      "li[hidden]:not(.filter-li-hide) .ace-callout-component",
    );

    this.identifyNumberOfItems();

    if (hiddenElements.length <= this.numberOfItem) {
      this.loadMoreBtn.classList.add("hidden");
      hiddenElements.forEach((element) =>
        element.parentElement.removeAttribute("hidden"),
      );
      return;
    }

    for (let i = 0; i < this.numberOfItem; i++) {
      hiddenElements[i].parentElement.removeAttribute("hidden");
    }
  }

  horizontalTabFilterRefresh(id) {
    if (!this.hasLoadMoreFeature) {
      super.horizontalTabFilterRefresh(id);
      return;
    }

    this.currentTab = id;
    this.tabNavigationButton.forEach((item) => {
      if (item.getAttribute("data-filter-tab-id") === id) {
        item.setAttribute("aria-current", true);
      } else {
        item.setAttribute("aria-current", false);
      }
    });
    this.componentHost
      .querySelectorAll(`.${CalloutContainer.CALLOUT_CLASS_NAMESPACE}`)
      .forEach((callout) => {
        callout.parentElement.classList.add("filter-li-hide");
        callout.parentElement.removeAttribute("hidden");
      });
    const elements = this.componentHost.querySelectorAll(
      `.${CalloutContainer.CALLOUT_CLASS_NAMESPACE}[data-filters*="${id}"]`,
    );
    elements.forEach((callout) => {
      callout.parentElement.classList.remove("filter-li-hide");
    });
    setTimeout(() => {
      document.dispatchEvent(
        new Event(CoreJS.CustomDomEventConstants.RESIZE_EVENT_WRAPPER),
      );
    }, 100);

    this.identifyNumberOfItems();

    if (elements.length <= this.numberOfItem) {
      this.loadMoreBtn.classList.add("hidden");
      return;
    }

    for (let i = this.numberOfItem; i < elements.length; i++) {
      elements[i].parentElement.setAttribute("hidden", true);
    }

    if (this.loadMoreBtn.classList.contains("hidden")) {
      this.loadMoreBtn.classList.remove("hidden");
    }
  }

  setAriaActiveTabFromURL() {
    const urlParams = new URLSearchParams(window.location.search);
    const activeTab = urlParams.get("callout-active-tab");

    if (!activeTab) return;

    const buttons = document.querySelectorAll(
      ".ace-callout-container-filters__tab-button",
    );
    const uppercaseActiveTab = activeTab.toUpperCase();
    const matchingActiveTab =
      MISMATCHED_TABS[uppercaseActiveTab] ?? uppercaseActiveTab;

    buttons.forEach((button) => {
      const buttonTab = button
        .getAttribute("data-filter-tab-id")
        ?.toUpperCase();

      if (buttonTab === matchingActiveTab) {
        button.setAttribute("aria-current", "true");
        button.classList.add("active");
        button.click();
      } else {
        button.classList.remove("active");
        button.setAttribute("aria-current", "false");
      }
    });
  }
}

CoreJS.BaseComponent.registerComponent(
  CalloutContainer.CLASS_NAMESPACE,
  FairmontCalloutContainer,
  true,
);
