import { HeaderNavigation as HeaderNavigationCore } from "@accor/ace-ui-core";
import { eventTracking, getDataLayer, pushEvent } from "@tracking";

export default class HeaderNavigation extends HeaderNavigationCore {
  static CLASS_NAMESPACE = "header-navigation";

  constructor(componentHost) {
    super(componentHost);
  }

  initialize() {
    // Check up cause there is 2 .header-navigation

    HeaderNavigationCore.BREAK_POINTS =
      CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.xl;
    super.initialize();

    if (!this.componentHost.dataset.initialized) {
      this.componentHost.dataset.initialized = "true";
    }

    this.handleStickyHeaderBehavior();
    this.trackNavigationGA4Events();
  }

  handleStickyHeaderBehavior() {
    const header = document.querySelector("header");
    let lastScrollY = 0;
    const SCROLL_THRESHOLD = 60;

    const toggleStickyHeader = () => {
      const currentScrollY = window.scrollY;
      if (window.innerWidth >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
        header?.classList.remove("sticky-header--hidden");
        return;
      }
      header?.classList.toggle(
        "sticky-header--hidden",
        currentScrollY > lastScrollY && currentScrollY > SCROLL_THRESHOLD,
      );
      lastScrollY = currentScrollY;
    };

    window.addEventListener(
      CoreJS.DomEventConstants.SCROLL,
      toggleStickyHeader,
    );
  }

  trackNavigationGA4Events() {
    this.dataLayer = getDataLayer();
    this.isBrandPage = this.dataLayer.pageName.includes("brand");

    this.mainNavigationTracking();
    this.ctaNavigationTracking();
    this.otherHeaderTracking();
  }

  mainNavigationTracking() {
    const btnSubMenu = this.menuMainContainer?.querySelectorAll(
      ".ace-header-navigation__menu-container .link-navigation__menu__link, .ace-header-navigation__home-container .link-navigation__menu__link",
    );

    btnSubMenu?.forEach((element) => {
      const dataTracking = element.dataset.tracking;
      const position = element.parentElement.dataset.menuPosition;

      const isInsideHeaderMenuProduct =
        element.closest(".header-menu-product") !== null;
      const isInsideHomeHeader =
        element.closest(".ace-header-navigation__home-container") !== null;

      const eventName = !isInsideHomeHeader
        ? "menu_interact"
        : "menu_interact_other";

      let moreBtnHeader;
      if (element.classList.contains("link-navigation__more-btn")) {
        moreBtnHeader = "more";
      } else {
        moreBtnHeader = `${dataTracking ? dataTracking.toLowerCase() : ""}`;
      }

      const trackingData = {
        menu_position: `${position || ""}`,
        menu_label: moreBtnHeader,
        ...(isInsideHeaderMenuProduct && { bloc_name: "main navigation" }),
      };

      eventTracking({
        node: element,
        eventName,
        trackingData,
        condition: () => {
          const isButton = element.tagName === "BUTTON";
          const ariaControls = element.getAttribute("aria-controls");
          return (
            !isButton ||
            (element.classList.contains("activeBtn") &&
              this.menuMainContainer
                ?.querySelector(`#${ariaControls}`)
                ?.classList.contains("showPanel"))
          );
        },
      });
    });
  }

  ctaNavigationTracking() {
    this.ctaNavigation = this.menuMainContainer?.querySelectorAll(
      ".ace-header-navigation__cta-container .cta-nav__link",
    );
    this.ctaNavigation?.forEach((cta, index) => {
      eventTracking({
        node: cta,
        eventName: "menu_interact_other",
        trackingData: {
          menu_label:
            this.ctaNavigation.length === 1
              ? "all loyalty"
              : index === 0
                ? "shop"
                : "all loyalty",
        },
      });
    });
  }

  otherHeaderTracking() {
    const eventName = "menu_interact_other";

    const otherSubNavigation = this.menuMainContainer?.querySelectorAll(
      ".ace-header-navigation__pos-container button.link-navigation__menu__link:not(.pos-container-geolocation__menu__back button, .pos-container-currency__menu__back button)",
    );

    if (otherSubNavigation.length) {
      otherSubNavigation?.forEach((node) => {
        const dataTracking = node.dataset?.tracking?.toLowerCase();
        const ariaControls = node?.getAttribute("aria-controls");

        eventTracking({
          node,
          eventName,
          trackingData: { menu_label: dataTracking },
          condition: () => {
            return (
              node.classList.contains("activeBtn") ||
              this.menuMainContainer
                ?.querySelector(`#${ariaControls}`)
                ?.classList.contains("showPanel")
            );
          },
        });
      });
    }

    const backHomeTracking = document.querySelector(
      ".ace-header-navigation__homelink .cta-backhome",
    );
    if (backHomeTracking) {
      eventTracking({
        node: backHomeTracking,
        eventName,
        trackingData: { menu_label: "fairmont home" },
      });
    }

    const logoTracking = document.querySelector(".ace-header__logo--link");
    if (logoTracking) {
      eventTracking({
        node: logoTracking,
        eventName,
        trackingData: {
          menu_label: this.isBrandPage ? "brand logo" : "property logo",
        },
      });
    }
  }

  /**
   * Overrides the `burgerMenuHandler` method from the parent class.
   * including tracking events and managing UI updates.
   */
  burgerMenuHandler() {
    if (!this.burgerMenubtn) return;

    const isOpening =
      this.burgerMenubtn.getAttribute("aria-expanded") === "false";
    const eventName = isOpening ? "menu_open" : "menu_close";

    this.burgerMenubtn.setAttribute(
      "aria-expanded",
      isOpening ? "true" : "false",
    );

    pushEvent({ eventName });

    if (isOpening) {
      this.closeSubMenuDropdownPanelHanlder();
      this.overlay.classList.add("enableOverlay");
      document.body.classList.add("disableScroll");
    } else {
      document.body.classList.remove("disableScroll");
      this.overlay.classList.remove("enableOverlay");
    }

    this.toggleModal();
  }
}

// Registering component in component factory.
CoreJS.BaseComponent.registerComponent(
  HeaderNavigationCore.CLASS_NAMESPACE,
  HeaderNavigation,
  true,
);
