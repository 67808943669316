import { eventTracking, extractOfferNameFromUrl } from "@tracking";

const TRACKED_ROOM_TEMPLATE = [
  "fairmont---hotel-homepage-template",
  "fairmont---hotel-room-landing-template",
  "fairmont---hotel-room-detail-template",
  "fairmont---hotel-mice-landing-template",
];

export const handleCalloutTracking = (callout: HTMLElement) => {
  const buttons = callout.querySelectorAll(".ace-button > a");
  if (!buttons.length) return;

  const [firstButton, secondButton] = Array.from(buttons);
  const template = document.querySelector('meta[name="template"]').getAttribute("content");

  if (shouldTrackOfferCallout(callout, template)) {
    trackOfferCallout(firstButton as HTMLAnchorElement, secondButton as HTMLAnchorElement);
  }

  if (shouldTrackRoomCallout(callout, template)) {
    trackRoomCallout(firstButton as HTMLAnchorElement, secondButton as HTMLAnchorElement);
  }
};

function shouldTrackOfferCallout(callout: HTMLElement, template: string) {
  const isFixedListDataSource = !!callout.closest("[data-target-datasourcetype='fixedList']");
  const isOfferDetailTemplate = !!callout.closest("[data-target-template='fairmont---hotel-offer-detail-template']");
  const isHomepageTemplate = template === "fairmont---brand-homepage-template";

  return (isHomepageTemplate && isFixedListDataSource) || isOfferDetailTemplate;
}

function shouldTrackRoomCallout(callout: HTMLElement, template: string) {
  const isRoomCallout = !!callout.closest(".callout-container-api");
  return isRoomCallout && TRACKED_ROOM_TEMPLATE.includes(template);
}

function trackOfferCallout(firstButton: HTMLAnchorElement, secondButton?: HTMLAnchorElement) {
  const offerName = extractOfferNameFromUrl(secondButton?.href) || extractOfferNameFromUrl(firstButton?.href);

  trackCalloutInteraction(firstButton, "offer bloc", `${offerName} - book offer cta`);
  trackCalloutInteraction(secondButton, "offer bloc", `${offerName} - see details`);
}

function trackRoomCallout(firstButton: HTMLAnchorElement, secondButton?: HTMLAnchorElement) {
  trackCalloutInteraction(firstButton, "rooms bloc", "check rates");
  trackCalloutInteraction(secondButton, "rooms bloc", "see details");
}

function trackCalloutInteraction(
  button: HTMLAnchorElement | undefined,
  blocName: string,
  blocInteraction: string
) {
  if (!button) return;

  eventTracking({
    node: button,
    eventName: "bloc_interact",
    trackingData: {
      bloc_name: blocName,
      bloc_interaction: blocInteraction,
    },
  });
}
