import { eventTracking } from "@tracking";

class UserConnectTracking {
  constructor(componentHost) {
    this.componentHost = componentHost;

    this.waitForAccountUpdate().then(() => {
      this.initEvents();
    });
  }

  waitForAccountUpdate() {
    return new Promise((resolve) => {
      const checkAccountStatus = () => {
        const connectedAccount = document.querySelector(
          ".ace-myaccount-connected:not(.acc-hide)",
        );
        const disconnectedAccount = document.querySelector(
          ".ace-myaccount-disconnected:not(.acc-hide)",
        );

        if (connectedAccount || disconnectedAccount) {
          resolve();
        }
      };

      checkAccountStatus();

      document.addEventListener("my-account-loaded", checkAccountStatus, {
        once: true,
      });
    });
  }

  initEvents() {
    this.isLoggedIn = !!this.componentHost.querySelector(
      ".ace-myaccount-connected:not(.acc-hide)",
    );
    this.accountContainer = this.componentHost.querySelector(
      this.isLoggedIn
        ? ".ace-myaccount-connected"
        : ".ace-myaccount-disconnected",
    );

    if (!this.accountContainer) return;

    this.trackOpenAccount();
    this.trackAccountMenuItems();
  }

  trackOpenAccount() {
    const openAccountButton = this.componentHost.querySelector(
      this.isLoggedIn
        ? "#ace-myaccount_login_connected"
        : "#ace-myaccount_login",
    );

    if (openAccountButton) {
      eventTracking({
        node: openAccountButton,
        eventName: "user_connect_open",
        trackingData: {
          user_is_logged: this.isLoggedIn ? "1" : "0",
        },
      });
    }
  }

  trackAccountMenuItems() {
    const accountMenu = this.accountContainer.querySelector(".ace-myaccount");
    if (!accountMenu) return;

    const trackingElements = [];

    const menuItems = accountMenu.querySelectorAll(".ace-myaccount__item");
    const primaryButton = accountMenu.querySelector(
      ".ace-myaccount .cmp-button--primary",
    );
    const secondaryButton = accountMenu.querySelector(".cmp-button--secondary");
    const logOutButton = this.componentHost.querySelector(
      "#cmp-my-account_login-sign-out",
    );

    let position = 1;

    if (!this.isLoggedIn) {
      if (primaryButton)
        trackingElements.push({
          node: primaryButton,
          menu_label: "become a member",
          menu_position: position++,
        });
      if (secondaryButton)
        trackingElements.push({
          node: secondaryButton,
          menu_label: "sign in",
          menu_position: position++,
        });
      if (menuItems.length === 1)
        trackingElements.push({
          node: menuItems[0],
          menu_label: "your bookings",
          menu_position: position++,
        });
    } else {
      if (menuItems.length >= 2) {
        trackingElements.push({
          node: menuItems[0],
          menu_label: "your loyalty account",
          menu_position: position++,
        });
        trackingElements.push({
          node: menuItems[1],
          menu_label: "your bookings",
          menu_position: position++,
        });
      }

      const allLists = this.accountContainer.querySelectorAll(
        ".ace-myaccount__list",
      );
      if (allLists.length > 1) {
        const usePoints = allLists[1]?.querySelector(".ace-myaccount__item");
        if (usePoints)
          trackingElements.push({
            node: usePoints,
            menu_label: "your points statement",
            menu_position: position++,
          });
      }

      if (logOutButton)
        trackingElements.push({
          node: logOutButton,
          menu_label: "log out",
          menu_position: position++,
        });
    }

    trackingElements.forEach(({ node, menu_label, menu_position }) => {
      eventTracking({
        node,
        eventName: "user_connect_interact",
        trackingData: {
          user_is_logged: this.isLoggedIn ? "1" : "0",
          menu_position: menu_position.toString(),
          menu_label,
        },
      });
    });
  }
}

new UserConnectTracking(document.body);
