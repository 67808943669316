import { handleCalloutProductTracking } from "./callout-product-tracking";

const CALLOUT_PRODUCT_HOST_SELECTOR = "ace-callout-product";
const CALLOUT_PRODUCT_COMPONENT_SELECTOR = ".ace-callout-product";

type CalloutProductElement = HTMLElement & { shadowRoot: ShadowRoot | null };

customElements.whenDefined(CALLOUT_PRODUCT_HOST_SELECTOR).then(() => {
  const calloutProductHosts = document.querySelectorAll<CalloutProductElement>(CALLOUT_PRODUCT_HOST_SELECTOR);

  calloutProductHosts.forEach((host) => {
    const shadowRoot = host.shadowRoot;

    if (!shadowRoot) return;

    const observer = new MutationObserver(() => {
      const renderedComponent = shadowRoot.querySelector(CALLOUT_PRODUCT_COMPONENT_SELECTOR);

      if (renderedComponent) {
        handleCalloutProductTracking(renderedComponent as HTMLElement);
        observer.disconnect();
      }
    });

    observer.observe(shadowRoot, { childList: true, subtree: true });
  });
});
