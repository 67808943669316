import { Callout } from "@accor/ace-ui-core";
import { handleCalloutTracking } from "./callout-tracking.ts";

export default class FairmontCallout extends Callout {
  constructor(componentHost) {
    super(componentHost);

    this.adaptButtonsHtmlForStyling();
  }

  adaptButtonsHtmlForStyling() {
    // this reconstruction function is made because of a bad html structure that we cannot update
    const content = this.componentHost.querySelector(".cmp-teaser__content");

    if (!content) return;

    const contentParent = content.parentNode;
    const reconstructedContent = document.createElement("div");
    reconstructedContent.classList.add("reconstructed-content-wrapper");

    reconstructedContent.append(content);

    const buttons = content.querySelectorAll(".ace-button");

    if (buttons.length) {
      const buttonsWrapper = document.createElement("div");
      buttonsWrapper.classList.add("ace-button__wrapper");

      buttons.forEach((button) => {
        buttonsWrapper.append(button);
      });

      reconstructedContent.append(buttonsWrapper);
    }

    contentParent.append(reconstructedContent);
  }

  /**
   * @override
   */
  setCalloutClickListener() {
    handleCalloutTracking(this.componentHost);
  }
}

CoreJS.BaseComponent.registerComponent(
  Callout.CLASS_NAMESPACE,
  FairmontCallout,
  true,
);
