import { MyAccount } from "@accor/ace-ui-core";
export default class FairmontMyAccount extends MyAccount {

  /** @inheritDoc */
  constructor(componentHost, componentName) {
    super(componentHost, componentName);
  }

  /** @inheritDoc */
  initialize() {
    super.initialize();
    this.setUpAutoLogin();
    this.setupConnectedViews();
  }

  setupMyAccount() {
    /**
     * `setupMyAccount` is called in ace-frontend my-account initialize. We want to avoid that as on Fairmont we should wait the auto login iframe to be loaded.
     * By doing so, we avoid calling it twice which causes inconsistency in login status used for tracking or to contextualize loyalty components display
     */
    if (!this.autoLoginIframeLoaded) {
      return;
    }
    
    const connectedMemberView = this.componentHost.querySelector('.ace-myaccount-connected');
    const connectedNonmemberView = this.componentHost.querySelector('.ace-myaccount-connected-nonmember');
    const disconnectedView = this.componentHost.querySelector('.ace-myaccount-disconnected');
    const oacTokenCookie = CoreJS.CookieHelper.getCookieValue('OAC_cms.accor');
    const oacAccorTokenCookie = CoreJS.CookieHelper.getCookieValue('OAC_all.accor');
    const dataRefresh = document.getElementById('data-refresh');
    if ((oacTokenCookie || oacAccorTokenCookie) && connectedMemberView && connectedNonmemberView && disconnectedView) {
      const lang = dataRefresh ? dataRefresh.getAttribute('data-language') : null;
      new CoreJS.XHRPromisifiedRequest(`/content/sling/servlets/ace/customer?language=${lang}`, CoreJS.XHRPromisifiedRequest.HTTP_METHOD_GET)
          .setRequestHeaders({})
          .executeRequest()
          .then((result) => {
            // Request completed
            disconnectedView.classList.add('acc-hide');
            // acc-hide class has a display: block for disconnectedView to avoid huge CLS, so we need hide it via styles
            disconnectedView.style.display = 'none';
            if (!result) {
              // No response/Error, disconnect user
              window.sessionStorage.removeItem(CoreJS.StorageConstants.USER_DATA);
              window.sessionStorage.removeItem(CoreJS.StorageConstants.ACCESS_CODE);
              window.sessionStorage.removeItem(CoreJS.StorageConstants.CLIENT_CODE);
              window.sessionStorage.removeItem(CoreJS.StorageConstants.LOYALTY_CARD);

              this.setupDisconnectedView(connectedMemberView, connectedNonmemberView, disconnectedView);
            } else {
              if (result.responseText === 'refresh') {
                // refresh required
                window.sessionStorage.removeItem(CoreJS.StorageConstants.USER_DATA);
                window.sessionStorage.removeItem(CoreJS.StorageConstants.ACCESS_CODE);
                window.sessionStorage.removeItem(CoreJS.StorageConstants.CLIENT_CODE);
                window.sessionStorage.removeItem(CoreJS.StorageConstants.LOYALTY_CARD);

                if (dataRefresh) {
                  this.setLocationCookieAndRedirect(dataRefresh.getAttribute('data-refresh-url'));
                }
              }
              try {
                const userData = JSON.parse(result.responseText);
                const name = `${userData.firstName} ${userData.lastName}`;
                if (!sessionStorage.getItem(CoreJS.StorageConstants.USER_DATA)) {
                  window.dataLayer.push({
                    'event': 'authentification',
                    'e_category': 'signin',
                    'e_action': 'type',
                    'e_label': 'connect'
                  });
                }
                window.sessionStorage.setItem(CoreJS.StorageConstants.USER_DATA, result.responseText);
                window.sessionStorage.setItem(CoreJS.StorageConstants.ACCESS_CODE, userData.accessCode ? userData.accessCode : '');
                window.sessionStorage.setItem(CoreJS.StorageConstants.CLIENT_CODE, userData.companySCCode ? userData.companySCCode : '');
                window.sessionStorage.setItem(CoreJS.StorageConstants.LOYALTY_CARD, userData.cardNumber ? userData.cardNumber : '');
                if (userData.status) {
                  // User loyalty member, member view
                  connectedNonmemberView.classList.add('acc-hide');
                  window.sessionStorage.setItem('cardNumber', `${userData.cardNumber}`);
                  this.setupConnectedView(name, userData.status, userData.loyaltyPoints, connectedMemberView);
                  this.setSignOutLinkListener('cmp-my-account_login-sign-out');
                } else {
                  // User not loyalty member, non-member view
                  connectedMemberView.classList.add('acc-hide');
                  window.sessionStorage.setItem('cardNumber', `${userData.cardNumber}`);
                  this.setupConnectedView(name, userData.status, userData.loyaltyPoints, connectedNonmemberView);
                  this.setSignOutLinkListener('cmp-my-account_login-sign-out_nonmember');
                }
              } catch (error) {
                console.error(error);
              }
            }
          })
          .catch(() => {
            window.sessionStorage.removeItem(CoreJS.StorageConstants.USER_DATA);
            window.sessionStorage.removeItem(CoreJS.StorageConstants.ACCESS_CODE);
            window.sessionStorage.removeItem(CoreJS.StorageConstants.CLIENT_CODE);
            window.sessionStorage.removeItem(CoreJS.StorageConstants.LOYALTY_CARD);
            this.setupDisconnectedView(connectedMemberView, connectedNonmemberView, disconnectedView);
          })
          .finally(() => {
            document.dispatchEvent(new CustomEvent('my-account-loaded'));
          });
    } else {
      window.sessionStorage.removeItem(CoreJS.StorageConstants.USER_DATA);
      window.sessionStorage.removeItem(CoreJS.StorageConstants.ACCESS_CODE);
      window.sessionStorage.removeItem(CoreJS.StorageConstants.CLIENT_CODE);
      window.sessionStorage.removeItem(CoreJS.StorageConstants.LOYALTY_CARD);
      this.setupDisconnectedView(connectedMemberView, connectedNonmemberView, disconnectedView);
      document.dispatchEvent(new CustomEvent('my-account-loaded'));
    }
  }

  setUpAutoLogin() {
    const autoLoginComponent = document.querySelector('[data-auto-login-iframe-src]');
    if (!autoLoginComponent) {
      return;
    }

    let autoLoginIframe = document.getElementById('auto-login-iframe');
    if (!autoLoginIframe) {
      const iframeSrc = autoLoginComponent.getAttribute('data-auto-login-iframe-src');

      // Create the iframe element
      autoLoginIframe = document.createElement("iframe");
      autoLoginIframe.id = "auto-login-iframe";
      autoLoginIframe.style.display = "none";
      autoLoginIframe.src = iframeSrc;

      // Append the iframe to the document body
      document.body.appendChild(autoLoginIframe);

      autoLoginIframe.addEventListener('load', () => {
        this.autoLoginIframeLoaded = true;
        this.setupMyAccount();
      });
    }
  }

  setupDisconnectedView(memberView, nonmemberView, disconnectedView) {
    if (memberView && nonmemberView && disconnectedView) {
      memberView.classList.add('acc-hide');
      nonmemberView.classList.add('acc-hide');
      disconnectedView.classList.remove('acc-hide');
      // Ensure we override the display none we set in setupMyAccount
      disconnectedView.style.display = 'block';
    }
  }

  setupConnectedViews() {
    const disconnectedView = this.componentHost.querySelector(".ace-myaccount-disconnected");
    const connectedMemberView = this.componentHost.querySelector(".ace-myaccount-connected");
    const connectedNonmemberView = this.componentHost.querySelector(".ace-myaccount-connected-nonmember");
    const headerButtonLabel = disconnectedView.querySelector(".cmp-button__text").textContent;

    [connectedMemberView, connectedNonmemberView].forEach(view => {
      view.querySelector(".cmp-button__text").textContent = headerButtonLabel;
    });
  }

  setSignInLinkListener() {
    const signInLink = document.getElementById('cmp-my-account_login-sign-in');
    if (signInLink) {
      signInLink.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
        const loginUrl = signInLink.getAttribute('data-href');
        this.setLocationCookieAndRedirect(loginUrl);
      });
    }
  }
}
// Register the MyAccount component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(
    FairmontMyAccount.CLASS_NAMESPACE,
    FairmontMyAccount,
    true,
);
