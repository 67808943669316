import { DataLayer } from "@accor/ace-ui-core";
import { getUserData } from "~/components/common/my-account/v1/js/user";

const ACQUISITION_DATA = {
  MERCHANT_ID: "merchantId",
  SOURCE_ID: "sourceId",
  PARTNER_ID: "partner_id",
}

const PAGE_VIEW_EVENT = "page_view";

export default class FairmontDataLayer extends DataLayer {

  // We need to override `initialize` since the static method `pushAllDataLayer` cannot be overridden.
  initialize() {
    const aquisitionTrackingApiPromise = new Promise((resolve) => {
      document.addEventListener(CoreJS.AquisitionTrackingApiIntegration.PUSH_DATALAYER_EVENT, resolve);
    });

    Promise.all([aquisitionTrackingApiPromise, getUserData()])
      .then(([pushDataLayerEvent, userData]) => {
        const dataLayer = pushDataLayerEvent.detail.datalayer;
        this.pushPageData(dataLayer, userData);
      });
  }

  pushPageData(dataLayer, userData) {
    if (!window.dataLayer?.push || !dataLayer) {
      return;
    }

    window.dataLayer.push({
      event: PAGE_VIEW_EVENT,
      page_data: this.getPageData(dataLayer),
      user_data: this.getUserData(userData),
    });
  }

  getPageData(dataLayer) {
    const {
      pageName: pagename,
      page_category,
      page_sub_category_level1,
      page_sub_category_level2,
      page_sub_category_level3,
      page_language
    } = dataLayer;

    return {
      pagename,
      page_category,
      page_sub_category_level1,
      page_sub_category_level2,
      page_sub_category_level3,
      page_language,
      error_type: "", // Investigations still ongoing as to when to populate this field
      ...this.getAcquisitionData(),
      ...this.getHotelData(dataLayer),
    }
  }

  getAcquisitionData() {
    const { searchParams } = new URL(window.location.href);

    return {
      merchant_id: searchParams.get(ACQUISITION_DATA.MERCHANT_ID) ?? "",
      source_id: searchParams.get(ACQUISITION_DATA.SOURCE_ID) ?? "",
      partner_id: searchParams.get(ACQUISITION_DATA.PARTNER_ID) ?? ""
    };
  }

  getHotelData(data) {
    const hotelKeys = [
      'hotel_rid_code',
      'hotel_name',
      'hotel_country_name',
      'hotel_city_name',
      'hotel_continent',
      'hotel_region'
    ];

    return Object.fromEntries(
      hotelKeys
        .map(key => [key, data[key]])
        // Exclude keys if their value is null or undefined meaning we're not on an hotel page.
        // They can still be empty strings on hotel pages, in which case we want to send them.
        .filter(([, value]) => value !== null && value !== undefined)
    );
  }

  getUserData(userData) {
    // They way my-account component implemented it, when no userData is found in sessionStorage, it means the user is not logged in.
    if (!userData) {
      return {
        user_id: "",
        user_city: "",
        user_country: "",
        user_is_logged: "0",
        user_all_member: "",
        user_loyalty_card_type: "",
      }
    }

    const { userId, status, city, country, cardCodeTARS } = userData;

    return {
      user_id: userId,
      user_city: city,
      user_country: country,
      user_is_logged: "1",
      user_all_member: status ? "yes" : "no",
      user_loyalty_card_type: cardCodeTARS,
    }
  }
}

CoreJS.BaseComponent.registerComponent(DataLayer.CLASS_NAMESPACE, FairmontDataLayer, true);
