import { CoreBookingEngine, CurrencySelector } from "@accor/ace-ui-core";
import Fuse from "fuse.js";
import i18n from "./i18n/autocomplete.json";
import bookingTracker from "@booking-tracking";
import { DATE_PICKER_READY_EVENT } from "~/components/common/core-booking-engine/v1/core-booking-engine/date-picker"

export default class BookingEngine extends CoreBookingEngine {
  /** @inheritDoc */
  constructor(componentHost, componentName) {
    super(componentHost, componentName);

    this.updateBookingEngineLayout();

    this.locationInput = this.componentHost.querySelector(
      '[id="search.destination.userlang"]',
    );
    this.locationContainer = this.componentHost.querySelector(
      '[id="autoComplete"]',
    );
    this.addRoomButton = this.componentHost.querySelector(
      "button.addButton[data-counter-type='increment']",
    );
    this.typingTimer;
    this.typingDelay = 200; //Delay in milliseconds
    this.searchLength = 3;
    [
      CoreJS.DomEventConstants.INPUT,
      CoreJS.DomEventConstants.CLICK,
      CoreJS.DomEventConstants.FOCUS_IN,
    ].forEach((listener) => {
      this.locationInput.addEventListener(listener, () => {
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(
          this.displaySuggestions,
          this.typingDelay,
        );
      });
    });
    window.addEventListener(CoreJS.DomEventConstants.CLICK, (event) => {
      this.setLocationExpanded(false);
      const targetDiv = this.componentHost.querySelector(
        ".ahAutocomplete__result",
      );
      if (targetDiv && !targetDiv.contains(event.target)) {
        targetDiv.remove();
      }
    });
    this.specialRatesCodes = {
      AAA_CAA_MEMBERS: "PRAAA1",
      GOVERNMENT: "PRGOV1",
      MILITARY_VETERAN: "PRVET1",
      SENIOR: "PRSEN1",
    };
    this.addRoomButton.addEventListener(
      CoreJS.DomEventConstants.CLICK,
      (event) => {
        bookingTracker.trackFormInteract({ form_action: "add room" });
      },
    );
  }

  async updateBookingEngineLayout() {
    const isPropertyPage = document.querySelector(
      ".ace-core-booking-engine__content--property",
    );

    try {
      if (isPropertyPage) {
        this.updateStaticDestinationField();
      } else {
        this.updateDestinationField();
      }
      this.changePositionForRoomAndGuests();
      this.updateErrorField();
      await this.changeCalendarTablePosition();
    } catch (error) {
      console.error("Booking Engine initialization error: ", error);
    } finally {
      requestAnimationFrame(this.addLoadedClass.bind(this));
    }
  }

  setLocationExpanded(value = true) {
    this.locationInput.setAttribute("aria-expanded", value);
  }
  fetchHotels() {
    try {
      const value = this.locationInput.value;
      const searchQuery = `${value}`;
      const suggestions = this.getStaticSuggestions();
      if (value.length == 0) {
        return suggestions;
      }

      const hotelsList = suggestions.flatMap((continent) =>
        continent.hotels.map((hotel) => ({
          name: hotel.hotel.name,
          id: hotel.hotel.id,
          city: hotel.city.geoEntityLabel,
          region: hotel.region?.geoEntityLabel,
          country: hotel.country.geoEntityLabel,
          continent: continent.geoEntityLabel,
        })),
      );
      let results = [];
      const options = {
        shouldSort: false,
        threshold: 0.0,
        ignoreLocation: true,
        keys: ["continent", "country", "region", "city", "name"],
      };

      const fuse = new Fuse(hotelsList, options);

      const resultsFuzzy = fuse.search(searchQuery);

      resultsFuzzy.forEach((result) => {
        const continentIndex = results.findIndex(
          (c) => c.geoEntityLabel === result.item.continent,
        );
        const hotel = {
          hotel: {
            name: result.item.name,
            id: result.item.id,
          },
          city: {
            geoEntityLabel: result.item.city,
          },
          region: {
            geoEntityLabel: result.item.region,
          },
          country: {
            geoEntityLabel: result.item.country,
          },
        };
        if (continentIndex === -1) {
          //not exist
          results.push({
            geoEntityLabel: result.item.continent,
            hotels: [hotel],
          });
        } else {
          results[continentIndex].hotels.push(hotel);
        }
      });

      return results;
    } catch (error) {
      console.error("Error fetching suggestions:", error);
      return [];
    }
  }

  getStaticSuggestions() {
    const staticSuggestionsElement = document.querySelector(
      "[data-all-hotels-auto-complete]",
    );
    return staticSuggestionsElement
      ? JSON.parse(
          staticSuggestionsElement.getAttribute(
            "data-all-hotels-auto-complete",
          ),
        )
      : [];
  }

  displaySuggestions = () => {
    this.setLocationExpanded();
    const suggestions = this.fetchHotels();
    let resultContainer = this.componentHost.querySelector(
      ".ahAutocomplete__result",
    );
    const hotelCodeInput = this.componentHost.querySelector(
      'input[name="search.destination.code"]',
    );
    const locationInput = this.locationInput;

    if (suggestions.length === 0 && this.locationInput.value.length === 0) {
      if (resultContainer) {
        resultContainer.remove();
      }
      hotelCodeInput.value = "";
      return;
    }
    //create result's display container
    if (!resultContainer) {
      resultContainer = document.createElement("div");
      resultContainer.classList.add("ahAutocomplete__result");
    } else {
      resultContainer.innerHTML = "";
    }

    //create result's list
    const listResult = document.createElement("ul");
    listResult.classList.add("ahAutocomplete__result__items");
    listResult.id = "destinationSuggest";
    listResult.setAttribute("role", "listbox");

    suggestions.forEach((region) => {
      //create region's hotels
      const regionSuggestion = document.createElement("div");
      regionSuggestion.textContent = region.geoEntityLabel;
      regionSuggestion.classList.add("region-label");

      const li = document.createElement("li");
      li.classList.add("ahAutocomplete__result__item");
      li.classList.add("region-result");
      li.appendChild(regionSuggestion);
      listResult.appendChild(li); //Add region

      region.hotels?.forEach((data) => {
        const suggestion = document.createElement("div");
        suggestion.classList.add("hotel-result");
        const suggestionCity = document.createElement("p");
        suggestionCity.textContent = data.city?.geoEntityLabel;
        suggestionCity.classList.add("hotel-result__city");
        suggestion.appendChild(suggestionCity);

        const suggestionState = document.createElement("p");
        suggestionState.classList.add("hotel-result__state");
        if (data.region?.geoEntityLabel != undefined) {
          suggestionState.textContent =
            data.region.geoEntityLabel + ", " + data.country?.geoEntityLabel;
        } else {
          suggestionState.textContent = data.country?.geoEntityLabel;
        }

        suggestion.appendChild(suggestionState);

        const suggestionHotelName = document.createElement("p");
        suggestionHotelName.textContent = data.hotel.name;
        suggestionHotelName.classList.add("hotel-result__name");
        suggestion.appendChild(suggestionHotelName);

        const li = document.createElement("li");
        li.setAttribute("data-hotelcode", data.hotel.id);
        li.setAttribute("id", data.hotel.id);
        li.setAttribute("role", "option");
        li.setAttribute(
          "aria-label",
          `${data.hotel.name}, ${data.city?.geoEntityLabel}`,
        );
        li.classList.add("ahAutocomplete__result__item");
        li.appendChild(suggestion);
        li.addEventListener(CoreJS.DomEventConstants.CLICK, function (event) {
          event.preventDefault();
          const hotelCode = this.getAttribute("data-hotelcode");
          if (hotelCodeInput) {
            hotelCodeInput.value = hotelCode;
            locationInput.value = data.hotel.name;
            resultContainer.remove();
          }
        });
        listResult.appendChild(li); //Add region li
      });
    });
    //no result
    if (suggestions.length == 0 && this.locationInput.value.length > 0) {
      const currentPageLanguage =
        i18n[document.documentElement.lang || i18n.en];
      const noResultMessage =
        currentPageLanguage?.["booking.autocomplete.noResult"] ||
        "No results found";

      //create no results
      const noResults = document.createElement("div");
      const noResultsLabel = document.createElement("span");
      noResultsLabel.textContent = noResultMessage;
      noResultsLabel.classList.add("no__results__label");
      const noResultsIcon = document.createElement("span");
      noResultsIcon.classList.add("no__results__icon");
      noResults.classList.add("no__results");
      noResults.appendChild(noResultsIcon);
      noResults.appendChild(noResultsLabel);

      const li = document.createElement("li");
      li.classList.add("ahAutocomplete__noresult__item");
      li.appendChild(noResults);
      listResult.appendChild(li); //Add region
    }
    resultContainer.appendChild(listResult);
    this.locationContainer.appendChild(resultContainer);
    this.handleAccessibility();
  };
  handleAccessibility() {
    let nextIndex = 0;
    const autocompleteElement = this.componentHost.querySelector(
      ".ahAutocomplete__result",
    );
    this.componentHost
      .querySelector(".ace-core-booking-engine__content.ace-date-component")
      .addEventListener("keydown", (e) => {
        const options = this.componentHost.querySelectorAll(
          ".ahAutocomplete__result__item[role='option']",
        );
        switch (e.key) {
          case "ArrowDown":
            if (nextIndex < options.length) {
              if (!nextIndex) {
                options.forEach((el) => el.setAttribute("tabindex", "0"));
              }
              e.preventDefault();
              const nextItem = options[nextIndex];
              nextItem.focus();
              this.locationInput.setAttribute(
                "aria-activedescendant",
                nextItem.id,
              );
              nextIndex++;
            }
            break;
          case "ArrowUp":
            if (nextIndex > 1) {
              e.preventDefault();
              const nextItem = options[nextIndex - 2];
              nextItem.focus();
              this.locationInput.setAttribute(
                "aria-activedescendant",
                nextItem.id,
              );
              nextIndex--;
            }
            break;
          case "Enter":
            this.setLocationExpanded(false);
            e.target.click();
            break;
          case "Tab":
          case "Escape":
            autocompleteElement.remove();
            break;
          default:
            break;
        }
      });
  }
  async changeCalendarTablePosition() {
    const updateContainers = () => {
      const checkInContainer = this.componentHost.querySelector(
        ".ace-core-booking-engine__check-in .duet-date__dialog-content"
      );
      const checkOutContainer = this.componentHost.querySelector(
        ".ace-core-booking-engine__check-out .duet-date__dialog-content"
      );
      if (checkInContainer && checkOutContainer) {
        this.updateCalendarContainer(checkInContainer);
        this.updateCalendarContainer(checkOutContainer);
        return true;
      }
      return false;
    };

    if (!updateContainers()) {
      await new Promise((resolve) => {
        document.addEventListener(DATE_PICKER_READY_EVENT, resolve, { once: true });
      });
      updateContainers();
    }
  }
  updateStaticDestinationField() {
    const destinationLabel = this.componentHost.querySelector(
      ".ace-core-booking-engine__autocomplete .ace-core-booking-engine__label",
    );
    const destinationLabelContainer = document.querySelector(
      ".ace-booking-engine-hotel-destination",
    );
    destinationLabelContainer.prepend(destinationLabel);
    const target = document.querySelector(
      ".ace-core-booking-engine__autocomplete",
    );
    const destinationIcon = document.createElement("div");
    destinationIcon.classList.add("autocomplete-icon");
    target.prepend(destinationIcon);
  }
  updateErrorField() {
    const form = document.querySelector(
      ".ace-core-booking-engine > form[name='bookingEngine']",
    );
    const formContent = document.querySelector(
      ".ace-core-booking-engine__content",
    );
    const errorFieldDesktop = document.querySelector(
      ".ace-core-booking-engine__error-desktop",
    );
    const errorFieldMobile = document.querySelector(
      ".ace-core-booking-engine__error-mobile",
    );
    form.insertBefore(errorFieldDesktop, formContent);
    form.insertBefore(errorFieldMobile, formContent);
  }
  updateCalendarContainer(container) {
    const table = container.querySelector(".duet-date__table");
    const div = document.createElement("div");
    div.classList.add("duet-date__table-container");
    div.appendChild(table);
    container.appendChild(div);
  }

  updateDestinationField() {
    const destinationLabel = this.componentHost.querySelector(
      ".ace-core-booking-engine__autocomplete .ace-core-booking-engine__label",
    );
    const destinationIcon = document.createElement("div");
    destinationIcon.classList.add("autocomplete-icon");
    const destinationLabelContainer = this.componentHost.querySelector(
      ".ace-core-booking-engine__autocomplete .ace-shared-acp-component",
    );
    destinationLabelContainer?.prepend(destinationLabel);
    destinationLabelContainer?.parentNode.prepend(destinationIcon);
  }
  errorGenerator = (
    errorBlock,
    id,
    message,
    componentGroup,
    errorGroup,
    event,
  ) => {
    this.formErrorCounter++;
    this.componentHost
      .querySelectorAll(errorBlock)
      ?.forEach((el) => el.classList.add("invalid"));
    this.errorStrings.push({
      errorBlock,
      id,
      message,
      componentGroup,
      errorGroup,
    });
    if (!this.event_form_errors.includes(event)) {
      this.event_form_errors.push(event);
      switch (event) {
        case "destination":
          {
            if (
              document.querySelector('[name="search.destination"]')?.value == ""
            ) {
              this.event_form_errors_type.push("blank");
            } else {
              this.event_form_errors_type.push("invalid value");
            }
          }
          break;
        case "arrival date":
        case "departure date":
          this.event_form_errors_type.push("invalid value");
          break;
        case "child age":
          this.event_form_errors_type.push("blank");
          break;
        case "other":
          this.event_form_errors_type.push("invalid value");
      }
    }
  };
  validateDestinationBlock = (formData) => {
    const destinationSelector = this.componentHost.querySelector(
      '[name="search.destination.code"]',
    );
    const userLangSelector = this.componentHost.querySelector(
      "[name='search.destination.userlang']",
    );
    const labelSelector = this.componentHost.querySelector(
      "[name='search.destination.labelSelected']",
    );

    const destination = destinationSelector?.value.replace(/["']/g, "");
    const userLang = userLangSelector?.value.replace(/["']/g, "");
    const label = labelSelector?.value.replace(/["']/g, "");

    if (destination !== "") {
      if (label?.length >= 4) {
        formData.append("search.destination.userlang", userLang);
      } else {
        // In other type of destination selection
        formData.append(destinationSelector?.name, destination);
      }

      this.componentHost
        .querySelectorAll(".ahAutocomplete__input__hidden input")
        ?.forEach((input) => {
          formData.append(input.name, input.value.replace(/["]/g, ""));
        });
      const offercheck = document.querySelector("body.offer-page");
      const participatingChain = "filter.brands";
      const selectiveTK = [
        "identification.rateAccess1",
        "identification.sub",
        "identification.flagExtendTk",
      ];
      let addValue = false;
      this.componentHost
        .querySelectorAll(".ace-core-booking-engine input")
        ?.forEach((input) => {
          if (offercheck) {
            if (input.name === participatingChain) {
              if (input.value) {
                const inputValue = input.value?.replaceAll(";", "|");
                formData.append(input.name, inputValue);
              }
            }
          }
          if (
            input.name === "identification.rateAccess1" &&
            this.dropdownPanel.dataset.promocode !== "true"
          ) {
            if (input.value) {
              addValue = true;
            }
          }
          // Add flagRagp in case of day use template
          if (input.classList.contains("day-use--identification-flagRagp")) {
            formData.append(input.name, input.value);
          }
          // Add flagRagp in case of day use template
          if (
            input.classList.contains(
              "day-use--identification-flagDayUse-flagExtendTk",
            ) &&
            !formData.has("identification.flagExtendTk")
          ) {
            formData.append("identification.flagExtendTk", "1");
          }
          if (addValue) {
            selectiveTK.forEach((val) => {
              if (input.name === val && !formData.has(input.name)) {
                formData.append(input.name, input.value);
              }
            });
          }
        });
      // TODO Should not pass here. To be removed after verification tests
    } else if (destination) {
      formData.append(destinationSelector?.name, destination);
    } else {
      if (
        this.componentHost.classList.contains(
          "core-booking-engine__homepagewrapper",
        )
      ) {
        if (userLang === "") {
          this.errorGenerator(
            ".ace-core-booking-engine__autocomplete",
            "search.destination.userlang",
            this.i18nStrings.destination_empty,
            "destination_empty",
            "destination_empty",
            "destination_empty",
          );
        } else {
          this.errorGenerator(
            ".ace-core-booking-engine__autocomplete",
            "search.destination.userlang",
            this.i18nStrings.destination_error,
            "destination_error",
            "destination_error",
            "destination_error",
          );
        }
      } else {
        this.errorGenerator(
          ".ace-core-booking-engine__autocomplete",
          "search.destination.userlang",
          this.i18nStrings.destination,
          "destination",
          "destination",
          "destination",
        );
      }
    }
  };
  validateStay = (formData) => {
    let checkInStatus = false;
    this.componentHost
      .querySelectorAll(
        '.core-booking-engine__date-search input:not([name="search.nightNb"])',
      )
      ?.forEach((input) => {
        if (input.value !== "") {
          if (input.value !== "default" && input.value !== "NaN") {
            formData.append(input.name, input.value);
          }
        } else {
          checkInStatus = true;
        }
      });
    if (checkInStatus) {
      this.errorGenerator(
        ".ace-core-booking-engine__check-in",
        "check-in-core-book-engine-12345",
        this.i18nStrings.dateFormat,
        "checkIn",
        "dateFormat",
        "arrival date",
      );
    }
    const checkout = this.componentHost.querySelector(
      '.core-booking-engine__date-search input[name="search.nightNb"]',
    );
    if (checkout.value !== "") {
      if (checkout.value !== "default") {
        formData.append(checkout.name, checkout.value);
      }
    } else {
      this.errorGenerator(
        ".ace-core-booking-engine__check-out",
        "check-out-core-book-engine-12345",
        this.i18nStrings.dateFormat,
        "checkOut",
        "dateFormat",
        "departure date",
      );
    }
  };
  displayErrorMessages = () => {
    this.errorBlocks[1].style.display = "flex";
    this.errorBlocks[0].style.display = "flex";
    const filteredErrors = this.getUniqueErrorListBy(
      this.errorStrings,
      "errorGroup",
    );

    this.errorBlocks?.forEach((block) => {
      block.querySelector(".error-count").innerHTML = this.formErrorCounter;
      let list = "";
      filteredErrors.forEach((item) => {
        list += `<li><a target="_self" href="#${item.id}" data-component-group="${item.componentGroup}"
         data-error-group="${item.errorGroup}" class="ui-body-01">${item.message}</a></li>`;
      });
      block.querySelector("ul").innerHTML = list;
      block.querySelector("ul li a")?.focus();
    });
    const errorBlock =
      window.innerWidth > CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg
        ? this.errorBlocks[0]
        : this.errorBlocks[1];
    const errorBlockHeight = errorBlock?.offsetHeight;
    this.rootStyle.style.setProperty(
      "--errorHeight",
      `${errorBlockHeight + 32}px`,
    );
  };
  changePositionForRoomAndGuests() {
    const roomAndGuestsIcon = document.createElement("div");
    roomAndGuestsIcon.classList.add("room-and-guests-icon");
    const labelInputIconContainer = document.createElement("div");
    labelInputIconContainer.classList.add(
      "room-and-guests-label-input-icon-container",
    );
    labelInputIconContainer.append(roomAndGuestsIcon);

    const labelInputContainer = document.createElement("div");
    labelInputContainer.classList.add("label-input-container");

    const roomAndGuestsText = this.componentHost.querySelector(
      ".ace-core-booking-engine__guests .ace-core-booking-engine__guests-title",
    );

    roomAndGuestsText.parentNode.prepend(labelInputIconContainer);
    labelInputIconContainer.append(labelInputContainer);

    const roomAndGuestsBtn = this.componentHost.querySelector(
      ".ace-core-booking-engine__guests button.ui-body-01.input",
    );
    labelInputContainer.append(roomAndGuestsText, roomAndGuestsBtn);
  }

  /**
   * Submit fairmont form
   */
  formSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();

    this.resetErrors();
    this.event_form_errors = [];
    this.event_form_errors_type = [];

    this.surfaceControlCheck();
    const queryParams = this.buildQueryParams(event.target);

    if (this.formErrorCounter > 0 || this.validateGuestBlock(queryParams)) {
      this.displayErrorMessages();
      this.bindErrorMessageEvents();
      this.errorEvent();
      return;
    }

    this.redirectToTarget(event, queryParams);
  }

  buildQueryParams(form) {
    const queryParams = new URLSearchParams();
    const appendIfPresent = (name, selector) => {
      const element = document.querySelector(selector);
      if (element) queryParams.append(name, element.value);
    };

    //languageCode
    queryParams.append("languageCode", document.documentElement.lang || "en");

    //client
    queryParams.append("client", "aem.fairmont");

    //currencyCode
    const currencyCode = CoreJS.CookieHelper.getCookieValue(
      CurrencySelector.CURRENCY_ZONE_COOKIE_NAME,
    );
    if (currencyCode) queryParams.append("currencyCode", currencyCode);

    //Hotel Code
    // We will handle both cases
    // We are on a property page, the hotel code will be in data 'data-hotelcode'
    // If we are on a brand page, the hotel code will be in a input
    const hotelCode = form.getAttribute("data-hotelcode");
    if (hotelCode) {
      queryParams.append("hotelCodes", hotelCode);
    } else {
      appendIfPresent("hotelCodes", 'input[name="search.destination.code"]');
    }

    //Date In
    appendIfPresent("dateIn", 'input[name="search.dateIn"]');

    const lengthOfStay = document.querySelector('input[name="search.nightNb"]');
    if (lengthOfStay) {
      queryParams.append(
        "lengthOfStayValue",
        lengthOfStay.value === "default" ? 1 : parseInt(lengthOfStay.value),
      );
    }
    //lenghtOfStayUnit
    queryParams.append("lenghtOfStayUnit", "NIGHT");

    // preferredRateCodes
    // We will handle both cases
    // We are on a property page, the hotel code will be in data 'data-offercodes'
    // If we are on a brand page, the hotel code will be in a input

    const offerCodes = form.getAttribute("data-offercodes");
    if (offerCodes) {
      queryParams.append("preferredRateCodes", offerCodes);
    } else {
      const preferredRateCodesDropDown = this.componentHost.querySelector(
        "#ace-customdropdown-button",
      );
      if (preferredRateCodesDropDown) {
        let preferredRateCodesValue = this.componentHost
          .querySelector("li.ace-customdropdown__item--selected")
          .getAttribute("data-value");
        if (preferredRateCodesValue == "") {
          preferredRateCodesValue = document.getElementById("pref-code").value;
        }
        if (preferredRateCodesValue != "") {
          queryParams.append("preferredRateCodes", preferredRateCodesValue);
        }
      }
    }

    const accessibleProducts = document.querySelector(
      'input[name="search.accessibleRooms"]',
    );
    if (accessibleProducts) {
      queryParams.append("accessibleProducts", accessibleProducts.checked);
    }

    appendIfPresent(
      "companyClientCode",
      'input[name="identification.reserverId"]',
    );
    appendIfPresent(
      "companyAccessCode",
      'input[name="identification.reserverContract"]',
    );
    appendIfPresent(
      "memberCardId",
      'input[name="identification.fidelityCard.number"]',
    );

    return queryParams;
  }

  validateGuestBlock = (queryParams) => {
    const totalRooms = parseInt(
      this.componentHost.querySelector('[data-input="room"]')?.value,
    );
    const guestBlock = this.componentHost.querySelector(
      ".ace-core-booking-engine__guests>.input",
    );
    let hasErrors = false;

    if (totalRooms > 0) {
      this.componentHost
        .querySelectorAll(".ace-room")
        .forEach((room, index) => {
          if (index < totalRooms) {
            room.querySelectorAll("input").forEach((guest) => {
              switch (guest.dataset.input) {
                case "adult":
                  queryParams.append(
                    `product[${index}][adultNumber]`,
                    guest.value,
                  );
                  break;

                case "child":
                  {
                    const totalChildren = parseInt(guest.value);
                    queryParams.append(
                      `product[${index}][childrenNumber]`,
                      totalChildren,
                    );

                    if (totalChildren > 0) {
                      hasErrors =
                        this.validateChildAges(
                          room,
                          index,
                          totalChildren,
                          guestBlock,
                          queryParams,
                        ) || hasErrors;
                    }
                  }

                  break;
              }
            });
          }
        });
    }

    return hasErrors;
  };

  validateChildAges(room, roomIndex, totalChildren, guestBlock, queryParams) {
    let hasErrors = false;

    room
      .querySelectorAll(".ace-dropdown__select")
      .forEach((child, childIndex) => {
        if (childIndex < totalChildren) {
          const childAge = child.getAttribute("data-value");
          if (childAge !== "-") {
            queryParams.append(
              `product[${roomIndex}][childrenAges][${childIndex}]`,
              childAge,
            );
          } else {
            hasErrors = true;
            const selector = child.getAttribute("id");
            this.errorGenerator(
              `#${selector}, .ace-core-booking-engine__guests`,
              `child-dropdown-button-${roomIndex}-${childIndex}`,
              this.i18nStrings.children,
              "composition",
              "children",
              "child age",
            );
          }
        }
      });

    return hasErrors;
  }

  redirectToTarget(event, queryParams) {
    const contextSite =
      event.target.querySelector("[type=submit]")?.dataset.contextSite;
    const actionURL = event.target.getAttribute("action");

    const redirectUrl = `${contextSite}${actionURL}?${queryParams.toString()}`;
    bookingTracker.trackFormSubmit();
    window.location.href = redirectUrl;
  }

  resetErrors() {
    this.errorResetHanlder();
    this.event_form_errors = [];
    this.event_form_errors_type = [];
  }

  errorEvent() {
    let trackingData = {};
    const countArray = [];
    const eventFormErrorBlank = [];
    let eventFormErrorInvalid = [];

    this.event_form_errors_type?.forEach((item, index) => {
      if (item == "blank") {
        countArray.push(index);
      }
    });
    this.event_form_errors?.forEach((object, index) => {
      if (countArray?.length > 0) {
        countArray.forEach((item) => {
          if (item == index) {
            eventFormErrorBlank.push(object);
          } else {
            if (!eventFormErrorInvalid.includes(object)) {
              eventFormErrorInvalid.push(object);
            }
          }
        });
      } else {
        eventFormErrorInvalid.push(object);
      }
    });
    eventFormErrorInvalid = eventFormErrorInvalid.filter((el) => {
      return !eventFormErrorBlank.includes(el);
    });

    if (eventFormErrorBlank?.length > 0) {
      const eventFieldsBlank = `${eventFormErrorBlank?.toString()?.replaceAll(",", "|")}`;
      trackingData = {
        form_action: "error",
        error_type: "blank",
        error_field: eventFieldsBlank,
      };
      bookingTracker.trackFormInteract(trackingData);
    }
    if (eventFormErrorInvalid?.length > 0) {
      const eventFieldsInvalid = `${eventFormErrorInvalid?.toString()?.replaceAll(",", "|")}`;
      trackingData = {
        form_action: "error",
        error_type: "invalid value",
        error_field: eventFieldsInvalid,
      };
      bookingTracker.trackFormInteract(trackingData);
    }
  }

  addLoadedClass() {
    this.componentHost.classList.add("loaded");
  }

  tagManagerHandler() {
    // No op for now.
    // We need to override this method to prevent old GAevent to be fired from ace-frontend booking engine and to later implement our own events
  }
}
// Registering component in component factory.
CoreJS.BaseComponent.registerComponent(
  BookingEngine.CLASS_NAMESPACE,
  BookingEngine,
  true,
);
