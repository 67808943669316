import { TagManager } from "@accor/ace-ui-core";

type DataLayer = {
  pageName?: string;
  [key: string]: unknown;
};

export const getDataLayer = (): DataLayer => {
  const dataLayerAttr = "data-cmp-datalayer";
  const dataLayer = document
    ?.querySelector(`[${dataLayerAttr}]`)
    ?.getAttribute(dataLayerAttr);
  try {
    return dataLayer ? JSON.parse(dataLayer) : {};
  } catch (error) {
    console.error("Error parsing data layer:", error);
    return {};
  }
};

interface EventTrackingInputs {
  node: Node | null;
  htmlEventType?: string;
  eventName: string;
  trackingData?: Record<string, any>;
  condition?: () => boolean;
}

export const eventTracking = ({
  node,
  // @ts-ignore
  htmlEventType = CoreJS.DomEventConstants.CLICK,
  eventName,
  trackingData = {},
  condition = () => true,
}: EventTrackingInputs): void => {
  if (!node) return;
  node.addEventListener(htmlEventType, () => {
    if (condition && !condition()) return;
    pushEvent({ eventName, trackingData });
  });
};

export const pushEvent = ({ eventName, trackingData = {} }): void => {
  const dataLayer = getDataLayer();
  if (!dataLayer) return;

  // empty the event data
  TagManager.trackEvent({ event_data: null });

  const event = {
    event: "GA4event",
    eventName,
    event_data: {
      pagename: dataLayer.pageName,
      ...trackingData,
    },
  };

  TagManager.trackEvent(event);
};

export const extractOfferNameFromUrl = (url: string): string =>
  url?.match(/offers\/([^.]+)\.html/)?.[1] ?? "";