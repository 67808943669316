// Stylesheets
import "./theme.scss";

// ACE Library JS
import "@accor/ace-ui-core";
import "@accor/ace-ui-core/src/components/common/immersive-heading/v1/immersive-heading/js/immersive-heading";
import "@accor/ace-ui-core/src/components/common/navigation-panel-product/v1/js/navigation-panel-product";
import "@accor/ace-ui-core/src/components/common/navigation-panel-destination/v1/js/navigation-panel-destination";
import "@accor/ace-ui-core/src/components/common/header-menu-product/v1/js/header-menu-product";
import "@accor/ace-ui-core/src/components/common/heading-hero/v1/js/heading-hero";
import "@accor/ace-ui-core/src/components/common/room-amenities/v1/js/room-amenities";
import "@accor/ace-ui-core/src/components/common/meeting-capacity-chart/v1/js/meeting-capacity-chart";

// Import here all JS overlays that are overrigind ace-ui-core library. By default, the full
// original lib is imported

// COMMON JS Components

// CONTENT JS Components
//import './components/content/billboard/v1/js/billboard.js';

// STRUCTURE JS Components
import "./components/structure/base-page-template/v1/js/base-page-template.js";
import "./components/content/list/v2/list.js";
import "./components/content/modal/v1/modal.js";
import "./components/content/gallery/v1/gallery.js";
import "./components/content/navigation-header/v1/navigation-header.js";
import "./components/content/key-features/v1/key-features";
import "./components/common/sticky-booking-engine/v2/sticky-booking-engine.js";
import "./components/common/sticky-booking-engine/v2/sticky-booking-engine__navigation/sticky-booking-engine__navigation.js";
import "./components/content/slideshow-services/v1/slideshow-services.js";
import "./components/common/callouts/v1/callout-container/js/callout-container.js";
import "./components/common/callouts/v1/callout/js/callout.js";
import "./components/common/core-booking-engine/v1/core-booking-engine/core-booking-engine.js";
import "./components/common/core-booking-engine/v1/core-booking-engine/date-picker.js";
import "./components/common/core-booking-engine/v1/core-booking-engine/composition.js";
import "./components/common/datalayer/datalayer.js";
import "./components/common/modal-information/modal-information.js";
import "./components/common/my-account/v1/js/my-account.js";
import "./components/common/callouts/v1/callout-product/callout-product.js";
import "./components/content/loyalty-billboard/v1/loyalty-billboard.js";
import "./components/content/loyalty-benefits/v1/loyalty-benefits.js";
import "./components/content/icon-list/v1/js/icon-list.js";
import "./components/common/table-container/table-container.js"

// Tracking JS
import "./components/tracking/index.js";

document.dispatchEvent(
  // @ts-ignore
  new Event(CoreJS.CustomDomEventConstants.THEME_LOADED_EVENT),
);
