import { Modal } from "@accor/ace-ui-core";
import FairmontModal from "~/components/content/modal/v1/modal";

export default class ModalInformation extends FairmontModal {
  static CLASS_NAMESPACE = "information-modal";

  initialize() {
    // Check if the modal markup is present in the component.
    // Backend will fix this by not rendering the component at all when it's not contributed.
    if (!this.componentHost.querySelector(".ace-modal")) {
      return;
    }

    super.initialize();
    this.setupModalBehavior();
  }

  setupModalBehavior() {
    super.openPopIn();

    this.modal = this.componentHost.querySelector(".ace-modal");

    if (this.modal) {
      this.modal.style.display = "flex";
    }
  }
}

CoreJS.BaseComponent.registerComponent(
  ModalInformation.CLASS_NAMESPACE,
  ModalInformation,
);
