import { Composition as CoreBookingEngineComposition } from "@accor/ace-ui-core";
import i18n from "./i18n.json";
export default class BookingEngineComposition extends CoreBookingEngineComposition {
  static DEFAULT_ADULT_VALUE = "2";
  static DEFAULT_CHILD_VALUE = "0";

  /** @inheritDoc */
  constructor(componentHost, componentName) {
    super(componentHost, componentName);

    window.addEventListener(CoreJS.DomEventConstants.LOAD, async () => {
      const parentNode = this.componentHost.parentNode;
      this.formElement = parentNode.closest("form");

      this.destinationInput = parentNode.querySelector(
        'input[name="search.destination.code"]',
      );
      this.guestsContainer = parentNode.querySelector(
        ".ace-core-booking-engine__guests",
      );
      this.guestDropdown = document.querySelector(
        ".ace-core-booking-engine__dropdown",
      );

      this.displayChildAgeInformation();

      if (this.guestDropdown) {
        this.initialGuestDropdownHTML = this.guestDropdown.innerHTML;
        this.previousGuestState = this.extractGuestState();
      }

      this.handleNewRoomAddition();

      if (
        this.destinationInput &&
        this.formElement &&
        this.formElement.hasAttribute("data-room-occupancy-dynamic-by-hotel")
      ) {
        this.observeDestinationChange();
      }
    }
    );
  }

  observeDestinationChange() {
    let lastHotelId = "";

    const observer = new MutationObserver(() => {
      const hotelId = this.destinationInput.value.trim();
      if (hotelId && hotelId !== lastHotelId) {
        lastHotelId = hotelId;
        this.fetchRoomOccupancy(hotelId);
      }
    });

    observer.observe(this.destinationInput, {
      attributes: true,
      attributeFilter: ["value"],
    });
  }

  async fetchRoomOccupancy(hotelId) {
    const url = `/content/sling/servlets/ace/booking/room-occupancy?hotelId=${encodeURIComponent(
      hotelId,
    )}&brand=fairmont`;
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
      }
      const data = await response.json();
      this.updateGuestsContainer(data.roomOccupancy);
      this.updateConfig(data.roomOccupancy);
      this.resetGuestDropdown();
    } catch (error) {
      console.error("Failed to fetch room occupancy:", error);
      return [];
    }
  }

  updateGuestsContainer(roomOccupancy) {
    if (!this.guestsContainer) {
      return;
    }

    const { maxRoom, maxPax, maxAdult, maxChild, maxChildAge } = roomOccupancy;
    Object.entries({
      maxRoom,
      maxPax,
      maxAdult,
      maxChild,
      maxChildAge,
    }).forEach(([key, value]) => {
      this.guestsContainer.dataset[key] = value;
    });
  }

  updateConfig(roomOccupancy) {
    if (!roomOccupancy) {
      return;
    }

    this.config = {
      room: {
        min: 1,
        max: parseInt(roomOccupancy.maxRoom),
      },
      adult: {
        min: 1,
        max: parseInt(roomOccupancy.maxAdult),
      },
      child: {
        min: 0,
        max: parseInt(roomOccupancy.maxChild),
      },
      maxChildAge: parseInt(roomOccupancy.maxChildAge),
      pax: {
        max: parseInt(roomOccupancy.maxPax),
      },
    };
  }

  resetGuestDropdown() {
    if (!this.guestDropdown || !this.initialGuestDropdownHTML) {
      return;
    }

    const newGuestState = this.extractGuestState();
    const hasChanged =
      JSON.stringify(newGuestState) !== JSON.stringify(this.previousGuestState);

    if (hasChanged) {
      this.guestDropdown.innerHTML = this.initialGuestDropdownHTML;

      if (this.config && this.config.adult && this.config.adult.max >= 2) {
        this.setDefaultRoomValues();
      }

      this.renderGuest();
      this.rebindEventListeners();
      this.openCompositionDropdownHandler();
      this.applyParentSelectionClasses();

      this.previousGuestState = this.extractGuestState();
    }

    this.displayChildAgeInformation();
  }

  setDefaultRoomValues() {
    const adultInput = this.guestDropdown.querySelector(
      'input[name="search.roomCriteria[0].adultNumber"]',
    );
    const childInput = this.guestDropdown.querySelector(
      'input[name="search.roomCriteria[0].childrenNumber"]',
    );

    if (adultInput) {
      adultInput.value = BookingEngineComposition.DEFAULT_ADULT_VALUE;
      //enable the minus button
      if(BookingEngineComposition.DEFAULT_ADULT_VALUE >= 2){
        if(adultInput.previousElementSibling.classList.contains('disabled')){
          const minusBtn = adultInput.previousElementSibling;
          minusBtn?.classList.remove('disabled');
          minusBtn?.removeAttribute("aria-disabled");
        }
      }
    }
    if (childInput) {
      childInput.value = BookingEngineComposition.DEFAULT_CHILD_VALUE;
    }
  }

  rebindEventListeners() {
    const dropdownContent = this.componentHost.querySelector(
      ".ace-core-booking-engine__dropdown--content",
    );
    if (dropdownContent) {
      dropdownContent.addEventListener(
        CoreJS.DomEventConstants.CLICK,
        (event) => {
          const targetElement = event.target;
          this.onClickLoadMarkup(targetElement);
        },
      );
    }

    this.handleNewRoomAddition();
  }

  applyParentSelectionClasses() {
    const parent = document.querySelector(".sticky-booking-engine");

    if (parent && !parent.classList.contains("selected")) {
      parent.classList.add("selected");
    }
  }

  handleNewRoomAddition() {
    const roomCounter = this.guestDropdown.querySelector(
      '[data-counter="room"]',
    );

    if (!roomCounter) return;

    roomCounter.addEventListener(CoreJS.DomEventConstants.CLICK, (event) => {
      const addRoomButton = event.target.closest(".ace-counter__plus");
      if (!addRoomButton) return;

      const initialRoomCount =
        this.guestDropdown.querySelectorAll(".ace-room").length;

      const observer = new MutationObserver(() => {
        const updatedRoomCount =
          this.guestDropdown.querySelectorAll(".ace-room").length;

        if (updatedRoomCount > initialRoomCount) {
          const newRoom =
            this.guestDropdown.querySelectorAll(".ace-room")[
              updatedRoomCount - 1
            ];

          if (newRoom) {
            this.displayChildAgeInformation(newRoom);
          }

          observer.disconnect();
        }
      });

      observer.observe(this.guestDropdown, { childList: true, subtree: true });
    });
  }

  displayChildAgeInformation(roomElement = null) {
    if (
      !this.guestDropdown ||
      !this.formElement ||
      !this.formElement.hasAttribute("data-display-max-child-age-information")
    ) {
      return;
    }

    const rooms = roomElement
      ? [roomElement]
      : this.guestDropdown.querySelectorAll(".ace-room");

    rooms.forEach((room) => {
      const childCounter = room.querySelector(
        '.ace-counter[data-counter="child"]',
      );

      if (!childCounter) return;

      const maxChildAge = this.config?.maxChildAge;
      const currentPageLanguage =
        i18n[document.documentElement.lang] || i18n.en;
      const content = `${currentPageLanguage["booking.child-until.label"]} ${maxChildAge} ${currentPageLanguage["booking.children.label.year"]}`;

      let ageInfoElement = childCounter.querySelector(".child-age-info");
      if (ageInfoElement) {
        ageInfoElement.textContent = content;
      } else {
        ageInfoElement = document.createElement("span");
        ageInfoElement.classList.add("child-age-info");
        ageInfoElement.textContent = content;

        const childHeading = childCounter.querySelector(
          ".ace-counter__heading-title",
        );
        if (childHeading) {
          childHeading.after(ageInfoElement);
        }
      }
    });
  }

  extractGuestState() {
    return Array.from(this.guestDropdown.querySelectorAll(".ace-room")).map(
      (room) => {
        return {
          adult: room.querySelector('input[name*="adultNumber"]')?.value || "0",
          child:
            room.querySelector('input[name*="childrenNumber"]')?.value || "0",
          roomId: room.getAttribute("room-id") || "unknown",
        };
      },
    );
  }

  onClickLoadMarkup(targetElement, onloadEvt = false) {
    // Setting eventlistner to the parent and calling functions based on target event.
    if (
      targetElement?.classList?.contains("addButton") ||
      targetElement?.classList?.contains("deleteButton")
    ) {
      this.counterHandler(targetElement);
      this.roomChangeHandler(targetElement?.getAttribute("data-counter-type"));
    } else if (
      targetElement?.parentElement?.classList?.contains("ace-counter__buttons")
    ) {
      if (targetElement?.classList.contains("disabled")) return;
      if (
        targetElement?.closest(".ace-counter")?.getAttribute("data-counter") ===
        "child"
      ) {
        this.counterHandler(targetElement);
        this.childrenChangeHandler(targetElement, onloadEvt);
      } else if (
        targetElement?.closest(".ace-counter")?.getAttribute("data-counter") ===
        "adult"
      ) {
        this.counterHandler(targetElement);
      }
    }
  }
}

// Registering component in component factory.
CoreJS.BaseComponent.registerComponent(
  BookingEngineComposition.CLASS_NAMESPACE,
  BookingEngineComposition,
  true,
);
