import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";

export default class KeyfeaturesFairmont extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = "key-features";
  constructor(componentHost) {
    super(componentHost);
  }

  initialize() {
    this.addClassIfImageContributed();
    this.initSwipper();
  }

  initSwipper() {
    const slides = this.componentHost.querySelectorAll(
      ".cmp-key-slideshow__content__list__item",
    );
    const slidesCount = slides.length;

    this.componentHost
      .querySelector(".cmp-key-slideshow__content")
      .classList.add("swiper");
    this.componentHost
      .querySelector(".cmp-key-slideshow__content__list")
      .classList.add("swiper-wrapper");

    slides &&
      slides.forEach((slide) => {
        slide.classList.add("swiper-slide");
      });

    function updateNavigationVisibility(swiper) {
      const slidesPerView = swiper.params.slidesPerView;
      const totalSlides = swiper.slides.length;

      const navigationBtnContainer = document.querySelector(
        ".key-features .cmp-key-slideshow__content__arrows",
      );
      if (totalSlides === slidesPerView) {
        navigationBtnContainer.style.display = "none";
      } else {
        navigationBtnContainer.style.display = "flex";
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const swiper = new Swiper(
      this.componentHost.querySelector(".cmp-key-slideshow__content"),
      {
        watchOverflow: true,
        modules: [Navigation, Pagination],
        navigation: false,
        pagination: {
          el: ".pagination__details",
          type: "custom",
          renderCustom(swiper) {
            const { slidesPerView } = swiper.params;

            const numberOfViews = Math.ceil(
              swiper.slides.length / slidesPerView,
            );
            const currentViewIndex =
              Math.ceil(swiper.activeIndex / slidesPerView) + 1;

            return `${currentViewIndex} / ${numberOfViews}`;
          },
        },
        breakpoints: {
          [CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.sm]: {
            slidesPerView: 1,
          },
          [CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md]: {
            slidesPerView: 2,
          },
          [CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg]: {
            slidesPerView: slidesCount === 2 ? 2 : 3,
          },
          [CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.xl]: {
            slidesPerView: slidesCount,
          },
        },
        on: {
          init(swiper) {
            document
              .querySelector(".key-features .pagination__right-arrow")
              .addEventListener("click", () => {
                const slidesPerView = swiper.params.slidesPerView;
                const nextIndex = Math.min(
                  swiper.activeIndex + slidesPerView,
                  swiper.slides.length - slidesPerView,
                );
                swiper.slideTo(nextIndex);
              });

            document
              .querySelector(".key-features .pagination__left-arrow")
              .addEventListener("click", () => {
                const slidesPerView = swiper.params.slidesPerView;
                const prevIndex = Math.max(
                  swiper.activeIndex - slidesPerView,
                  0,
                );
                swiper.slideTo(prevIndex);
              });

            updateNavigationVisibility(swiper);
          },
          resize(swiper) {
            updateNavigationVisibility(swiper);
          },
          breakpoint: function (swiper) {
            const currentBreakpoint = Number(swiper.currentBreakpoint);

            // Apply specific class based on the active breakpoint
            if (
              currentBreakpoint ===
              CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md
            ) {
              slides.forEach((slide) => {
                slide.classList.remove("ratio-11-5");
              });
            } else if (
              currentBreakpoint ===
              CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.xl
            ) {
              if (slidesCount == 2 || slidesCount == 3 || slidesCount == 4) {
                slides.forEach((slide) => {
                  slide.classList.add("ratio-11-5");
                });
              }
            }
          },
        },
      },
    );
  }

  addClassIfImageContributed() {
    const slideshow = this.componentHost.querySelector(".cmp-key-slideshow");

    const list = slideshow.querySelector(".cmp-key-slideshow__content__list");

    const items = list?.querySelectorAll(
      ".cmp-key-slideshow__content__list__item",
    );

    items &&
      items.forEach((item) => {
        if (item.querySelector(".image-container")) {
          item.classList.add("has-hover-img");
        }
      });
  }
}

CoreJS.BaseComponent.registerComponent(
  KeyfeaturesFairmont.CLASS_NAMESPACE,
  KeyfeaturesFairmont,
    true
);
