import { eventTracking, pushEvent } from "../tracking";

class BookingEngineTracking {
  private static TRACKING_EVENT = {
    FORM_INTERACT: "booking_form_interact",
    FORM_SUBMIT: "booking_form_submit",
    BOOKING_OPEN: "bloc_interact",
  };

  trackFormInteract(trackingData = {}): void {
    pushEvent({
      eventName: BookingEngineTracking.TRACKING_EVENT.FORM_INTERACT,
      trackingData: trackingData,
    });
  }
  trackFormSubmit(): void {
    pushEvent({
      eventName: BookingEngineTracking.TRACKING_EVENT.FORM_SUBMIT,
    });
  }
  trackBookingOpen(): void {
    pushEvent({
      eventName: BookingEngineTracking.TRACKING_EVENT.BOOKING_OPEN,
      trackingData: {
        bloc_interaction: "book now",
        bloc_name: "book now - sticky",
      },
    });
  }
}
export default new BookingEngineTracking();
