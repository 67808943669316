import Swiper from "swiper";
import { Navigation, EffectFade } from "swiper/modules";

const commonSwiperConfig = {
  allowTouchMove: false,
  slidesPerView: 1,
  loop: false,
  navigation: false,
  spaceBetween: 0,
};

function initSwipers() {
  if (!document.querySelector(".slideshow")) {
    return;
  }

  const backgroundSwiper = new Swiper(".slideshow .slides", {
    ...commonSwiperConfig,
    modules: [EffectFade],
    effect: "fade",
    speed: 1500,
    keyboard: {
      enabled: false,
    },
  });
  const contentSwiper = new Swiper(".slideshow .card-body", {
    ...commonSwiperConfig,
    effect: "slide",
    speed: 700,
    on: {
      slideChange: function (swiper) {
        swiper.slides.forEach((panel, index) => {
          if (index === swiper.activeIndex) {
            panel.setAttribute("tabindex", "-1");
            panel.removeAttribute("aria-hidden");
            panel.removeAttribute("inert");
          } else {
            panel.setAttribute("inert", "");
            panel.setAttribute("aria-hidden", "true");
            panel.removeAttribute("tabindex");
          }
        });
      },
    },
  });

  const linksSwiper = new Swiper(".slideshow .card-header", {
    modules: [Navigation],
    speed: 700,
    slidesPerView: "auto",
    spaceBetween: 32,
    navigation: {
      prevEl: ".slideshow .card-header .card-header-btn.card-header-prev",
      nextEl: ".slideshow .card-header .card-header-btn.card-header-next",
    },
    keyboard: {
      enabled: true,
      onlyInViewport: false,
    },
    on: {
      init(swiper) {
        const { slidesPerView } = swiper.params;
        const totalSlides = swiper.slides.length;
        const currentSlidesLength = getVisibleSlidesLength(
          swiper,
          totalSlides,
          slidesPerView,
        );

        for (let i = 0; i < currentSlidesLength; i++) {
          const slide = document.querySelector(
            `.slideshow .card-header .swiper-wrapper button[data-index="${i}"]`,
          );
          slide?.setAttribute("tabindex", (i + 1).toString());
        }
        const nextBtn = document.querySelector(
          ".slideshow .card-header .card-header-next",
        );
        nextBtn?.setAttribute("tabindex", currentSlidesLength + 1);
        const contentBtn = document.querySelector(
          ".slideshow .card-body .card-btn",
        );
        contentBtn?.setAttribute("tabindex", currentSlidesLength + 2);
      },
      slideChange: function (swiper) {
        const prevBtn = document.querySelector(
          ".slideshow .card-header .card-header-prev",
        );
        const nextBtn = document.querySelector(
          ".slideshow .card-header .card-header-next",
        );
        prevBtn?.setAttribute("tabindex", "-1");
        nextBtn?.setAttribute("tabindex", "-1");
        swiper.slides.forEach((slide) => {
          slide.querySelector("button")?.setAttribute("tabindex", "-1");
        });

        const { activeIndex } = swiper;
        const { slidesPerView } = swiper.params;
        const totalSlides = swiper.slides.length;
        const currentSlidesLength = getVisibleSlidesLength(
          swiper,
          totalSlides,
          slidesPerView,
        );

        const limit = activeIndex + currentSlidesLength;

        let start = 1;
        if (activeIndex > 0) {
          start = 2;
        }

        if (start > 1) {
          prevBtn?.setAttribute("tabindex", start.toString());
        }

        for (let i = activeIndex; i < limit; i++) {
          const slide = document.querySelector(
            `.slideshow .card-header .swiper-wrapper button[data-index="${i}"]`,
          );
          slide?.setAttribute("tabindex", start.toString());
          start++;
        }

        nextBtn?.setAttribute("tabindex", start.toString());
        const contentBtn = document.querySelector(
          ".slideshow .card-body .card-btn",
        );
        contentBtn?.setAttribute("tabindex", (start + 1).toString());
      },
    },
  });

  function getVisibleSlidesLength(swiper, totalSlides, slidesPerView) {
    if (swiper.isEnd && totalSlides % slidesPerView !== 0) {
      return totalSlides % slidesPerView;
    }
    return slidesPerView;
  }

  const cardHeader = document.querySelector(
    ".slideshow .card-header .swiper-wrapper",
  );
  cardHeader.addEventListener("click", (event) => {
    const link = event.target;

    if (link.tagName !== "BUTTON") {
      return;
    }

    const { index } = link.dataset;

    if (!index) {
      return;
    }

    const parsedIndex = Number.parseInt(index, 10);
    const currentActiveLink = document.querySelector(
      ".slideshow .card-header button.active",
    );
    currentActiveLink.setAttribute("aria-selected", "false");

    currentActiveLink?.classList.remove("active");
    const nextActiveLink = document.querySelector(
      `.slideshow .card-header button[data-index="${parsedIndex}"]`,
    );

    nextActiveLink?.classList.add("active");
    nextActiveLink.setAttribute("aria-selected", "true");

    backgroundSwiper.slideTo(parsedIndex);
    contentSwiper.slideTo(parsedIndex);

    const { activeIndex } = linksSwiper;
    const slidesPerView = linksSwiper.slidesPerViewDynamic();
    if (parsedIndex === activeIndex) {
      linksSwiper.slidePrev();
    } else if (parsedIndex === activeIndex + slidesPerView - 1) {
      linksSwiper.slideNext();
    }

    linksSwiper.slides
      .forEach((slide) => {
        const button = slide.querySelector("button");
        if (button.classList.contains("active")) {
          button.removeAttribute("tabindex");
        } else {
          button.setAttribute("tabindex", "-1");
        }
      });
  });

  cardHeader.addEventListener("keydown", (event) => {
    if (event.key === "ArrowRight" || event.key === "ArrowLeft") {
      event.preventDefault();
      const link = event.target;

      if (link.tagName === "BUTTON") {
        const currentIndex = Number.parseInt(link.getAttribute("data-index"), 10);
        const linksSlidesLength = linksSwiper.slides.length;
        let newIndex = event.key === "ArrowLeft" ? currentIndex - 1 : currentIndex + 1;

        if (newIndex < 0) {
          newIndex = linksSlidesLength - 1;
        } else if (newIndex >= linksSlidesLength) {
          newIndex = 0;
        }
        const linkToActivate = cardHeader.querySelector(`button[data-index="${newIndex}"]`);
        linkToActivate?.focus();
        linkToActivate?.click();
      }
    }
  });
}

initSwipers();
