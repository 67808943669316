/**
 * @author Jonas.Fournel
 */
import { BasePageTemplate } from "@accor/ace-ui-core"

export default class FAIRMONTBasePageTemplate extends BasePageTemplate {
  /** @inheritDoc */
  constructor(componentHost) {
    super(componentHost)
  }
}

CoreJS.BaseComponent.registerComponent(BasePageTemplate.CLASS_NAMESPACE, FAIRMONTBasePageTemplate, true)
