import { IconList } from "@accor/ace-ui-core";
import Swiper from "swiper";

export default class FairmontIconList extends IconList {
  static CLASS_NAMESPACE = "ace-icon-list-component";

  /** @inheritDoc */

  constructor(componentHost) {
    super(componentHost);
  }
  /** @inheritDoc */

  initialize() {
    super.initialize();

    this.setupListCarousel();
  }

  setupListCarousel() {
    const carouselListElement = this.componentHost.querySelector(
      ".listCarousel",
    );

    if (!carouselListElement) {
      return;
    }

    const contentElement = this.componentHost.querySelector(".ace-footer-icon");

    if (!contentElement) {
      console.warn("Slideshow content element not found");
      return;
    }

    const slides = this.componentHost.querySelectorAll(
      ".ace-footer-icon__list--item",
    );

    if (!slides.length) {
      console.warn("No slides found");
      return;
    }

    contentElement.classList.add("swiper");
    carouselListElement.classList.add("swiper-wrapper");
    slides.forEach(slide => slide.classList.add("swiper-slide"));

    this.setupSwiper(contentElement);
  }

  setupSwiper(contentElement) {
    const swiper = new Swiper(contentElement, {
      watchOverflow: true,
      slidesPerView: 2,
      breakpoints: {
        [CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md]: {
          slidesPerView: 4,
        },
        [CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg]: {
          slidesPerView: 5,
        },
        [CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.xl]: {
          slidesPerView: 10,
        },
      },
    });

    this.setupSwiperNavigation(swiper);
  }

  setupSwiperNavigation(swiper) {
    const { prevButton, nextButton } = this.createNavigationArrows(swiper);

    prevButton.disabled = swiper.isBeginning;
    nextButton.disabled = swiper.isEnd;

    prevButton.addEventListener('click', () => {
      swiper.slideTo(swiper.activeIndex - 3);
    });

    nextButton.addEventListener('click', () => {
      swiper.slideTo(swiper.activeIndex + 3);
    });

    swiper.on('slideChange', () => {
      prevButton.disabled = swiper.isBeginning;
      nextButton.disabled = swiper.isEnd;
    });
  }

  createNavigationArrows(swiper) {
    const prevButton = document.createElement("button");
    const nextButton = document.createElement("button");

    prevButton.classList.add("swiper-button-prev");
    nextButton.classList.add("swiper-button-next");
    swiper.el.prepend(nextButton);
    swiper.el.prepend(prevButton);

    return { prevButton, nextButton };
  }
}

CoreJS.BaseComponent.registerComponent(
  FairmontIconList.CLASS_NAMESPACE,
  FairmontIconList,
  true,
);
