import { getUserData } from "~/components/common/my-account/v1/js/user";

export default class LoyaltyBillboard extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = "loyalty";

  constructor(componentHost, componentName) {
    super(componentHost, componentName);
  }

  async initialize() {
    const userIsLoggedIn = await getUserData();
    this.componentHost.style.display = userIsLoggedIn ? "none" : "block";
  }
}

CoreJS.BaseComponent.registerComponent(
  LoyaltyBillboard.CLASS_NAMESPACE,
  LoyaltyBillboard,
  true,
);
