import { Modal } from "@accor/ace-ui-core";
import {
  handleNewsletterSubscription,
  displayCountryNameOptions,
  displayCountryCodeOptions,
  displayHonorificOptions,
  displayLanguageOptions,
  subscribe,
  displayUserIsSubscribed,
  removeErrorsFromForm,
} from "../../../common/newsletter/v1/newsletter";
import { success } from "../../../common/newsletter/v1/newsletter.json";
import { pushEvent } from "../../../../utils/tracking";

export default class FairmontModal extends Modal {
  /** @inheritDoc */
  constructor(componentHost, componentName) {
    super(componentHost, componentName);
    this.inNewsletter = componentHost.parentElement.classList.contains(
      "ace-newsletter__content",
    );

    this.iframeReloaded = false;

    if (this.inNewsletter) {
      this.host = this.componentHost.parentElement;
      this.isFooter = this.host.closest("footer") ? true : false;
      this.userHasSubscribed = false;

      this.formEl = this.host.querySelector("form");

      displayCountryNameOptions(this.formEl);
      displayCountryCodeOptions(this.formEl);
      displayHonorificOptions(this.formEl);
      displayLanguageOptions(this.formEl);

      this.formEl.addEventListener("submit", async (event) => {
        event.preventDefault();
        this.userHasSubscribed = await subscribe(event.target, () => {
          this.closePopIn();
        });

        if (this.userHasSubscribed) {
          pushEvent({
            eventName: "newsletter_submit",
            trackingData: {
              newsletter_name: "fairmont",
            },
          });
        }
      });
    }
  }

  initialize() {
    this.popUpLink = this.componentHost.querySelector("[data-modal]");
    this.closeButton = this.componentHost.querySelector(
      ".ace-modal__close-button button",
    );
    this.modal = this.componentHost.querySelector(".ace-modal");
    this.boundOnKeyDown = this.onKeyDown.bind(this);
    this.boundCloseModalWindowClick = this.closeModalWindowClick.bind(this);

    this.closeButton.addEventListener(
      CoreJS.DomEventConstants.CLICK,
      this.closePopIn.bind(this),
    );

    if (this.popUpLink) {
      this.popUpLink.addEventListener(
        CoreJS.DomEventConstants.CLICK,
        this.onPopUpLinkClick.bind(this),
      );
    }
  }

  async onPopUpLinkClick(event) {
    if (this.inNewsletter) {
      handleNewsletterSubscription(this.host, () => {
        removeErrorsFromForm(this.formEl);
        this.openPopIn(event);
        const blockName = this.isFooter
          ? "newsletter footer"
          : "newsletter banner";
        const blockInteraction = this.isFooter
          ? "open footer newsletter form"
          : "open newsletter form";
        pushEvent({
          eventName: "newsletter_interact",
          trackingData: {
            bloc_name: blockName,
            bloc_interaction: blockInteraction,
          },
        });
      });
      return;
    }

    this.openPopIn(event);
  }

  openPopIn(event) {
    event?.stopPropagation();
    const popShow = this.modal;
    popShow.style.display = "block";
    popShow.setAttribute("aria-modal", "true");
    document.body.classList.add("disable-scroll-modal");

    this.modal.setAttribute("tabindex", "0");
    this.modal.focus();

    document.addEventListener(
      CoreJS.DomEventConstants.KEY_DOWN,
      this.boundOnKeyDown,
    );
    document.addEventListener(
      CoreJS.DomEventConstants.CLICK,
      this.boundCloseModalWindowClick,
    );

    const iframe = this.componentHost.querySelector("iframe");

    // The virtual tour's iframe content does not work properly unless it has a defined height at load time.
    if (iframe) {
      iframe.src = iframe.src;
      this.iframeReloaded = true;
    }
  }

  closePopIn() {
    if (this.inNewsletter && this.userHasSubscribed) {
      const language = document.documentElement.lang;
      const message = success.banner[language] || success.banner.en;

      displayUserIsSubscribed(this.host, message);
    }

    const popShow = this.modal;
    popShow.style.display = "none";
    popShow.setAttribute("aria-modal", "false");
    document.body.classList.remove("disable-scroll-modal");

    if (this.popUpLink) {
      this.popUpLink.focus();
    } else {
      document.documentElement.setAttribute("tabindex", "0");
      document.documentElement.focus();
      document.documentElement.removeAttribute("tabindex");
    }

    this.modal.setAttribute("tabindex", "-1");

    document.removeEventListener(
      CoreJS.DomEventConstants.KEY_DOWN,
      this.boundOnKeyDown,
    );
    document.removeEventListener(
      CoreJS.DomEventConstants.CLICK,
      this.boundCloseModalWindowClick,
    );
  }

  closeModalWindowClick(event) {
    // OneTrust modal can comes on top of our modals, so we need to exclude it from the outside clicks
    const isOneTrustModalClick = document
      .getElementById("onetrust-consent-sdk")
      ?.contains(event.target);
    const isInsideModalClick = this.modal
      .querySelector(".ace-modal__content")
      .contains(event.target);

    if (!isOneTrustModalClick && !isInsideModalClick) {
      this.closePopIn();
    }
  }

  onKeyDown(event) {
    this.trapFocus(event);
    this.closeOnEscape(event);
  }

  closeOnEscape(event) {
    if (event.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.esc) {
      this.closePopIn();
    }
  }

  trapFocus(event) {
    if (event.key !== CoreJS.Constants.KEYBOARD_EVENT_KEYS.tab) {
      return;
    }

    const focusable = this.modal.querySelectorAll(
      "input, button, select, textarea, a, [tabindex]:not([tabindex='-1'])",
    );

    if (!focusable.length) {
      return;
    }

    const first = focusable[0];
    const last = focusable[focusable.length - 1];

    if (event.shiftKey && event.target === first) {
      last.focus();
      event.preventDefault();
      return;
    }

    if (!event.shiftKey && event.target === last) {
      first.focus();
      event.preventDefault();
      return;
    }
  }
}

CoreJS.BaseComponent.registerComponent(
  Modal.CLASS_NAMESPACE,
  FairmontModal,
  true,
);
