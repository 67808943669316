import { eventTracking, getDataLayer } from "@tracking";

type InteractionConfig = {
  selector: string;
  label: string;
};

const CALLOUT_CARD_SELECTOR = ".ads-card-product";
const CALLOUT_CONTAINER_SELECTOR = ".ace-callout-product__container";
const CALLOUT_CTAS_SELECTOR = ".ads-card-product__content__cta";
const TARGET_PAGE_NAME = "brand::list::offer transactional";
const EVENT_NAME_INTERACT = "bloc_interact";
const BLOCK_NAME_HOTEL = "hotel bloc";
const INTERACTIONS: InteractionConfig[] = [
  {
    selector: ".ads-card-product__content__cta .ads-button",
    label: "book offer",
  },
  {
    selector: ".ads-card-product__content__cta .ads-link",
    label: "see details",
  },
];

export const handleCalloutProductTracking = (calloutProduct: HTMLElement) => {
  if (!shouldTrackPage()) {
    return;
  }

  const container = calloutProduct.querySelector(CALLOUT_CONTAINER_SELECTOR) as HTMLElement;

  setupCalloutProductTracking(container);
  observeNewCalloutCards(container);
};

// Only specific pages where the Callout Api v3 is offering booking opportunities are tracked for MVP
const shouldTrackPage = (): boolean => {
  const { pageName } = getDataLayer();
  return Boolean(pageName?.includes(TARGET_PAGE_NAME));
};

const setupCalloutProductTracking = (calloutProduct: HTMLElement) => {
  calloutProduct.querySelectorAll(CALLOUT_CARD_SELECTOR).forEach(calloutCard => {
    setupCalloutCardTracking(calloutCard as HTMLElement);
  });
};

const setupCalloutCardTracking = (calloutCard: HTMLElement) => {
  const calloutCardCtas = calloutCard.querySelector(CALLOUT_CTAS_SELECTOR);

  if (!calloutCardCtas) {
    return;
  }

  INTERACTIONS.forEach(({ selector, label }: InteractionConfig) => {
    const element = calloutCard.querySelector(selector);

    if (element) {
      setupHotelBlockInteractionTracking(element as HTMLElement, label);
    }
  });

  // Even when cards are not removed, the buttons are replaced when some filtering happens.
  // Mainly happens when filtering via chips. We then need to add back the listeners to the new buttons...
  // All this DOM manipulation is done by the vue component and MutationObserver is just a workaround for MVP.
  const observer = new MutationObserver((mutationsList) => {
    const buttonsReplaced = mutationsList.some(
      (mutation) => mutation.type === "childList" && mutation.removedNodes.length
    );

    if (buttonsReplaced) {
      setupCalloutCardTracking(calloutCard as HTMLElement);
      observer.disconnect();
    }
  });

  observer.observe(calloutCardCtas, {
    childList: true,
  });
};

const setupHotelBlockInteractionTracking = (element: HTMLElement, interaction: string) => {
  eventTracking({
    eventName: EVENT_NAME_INTERACT,
    node: element,
    trackingData: {
      bloc_name: BLOCK_NAME_HOTEL,
      bloc_interaction: interaction,
    },
  });
};

// Card are dynamically added to the container when switching filtering tabs back and forth.
// So we need to add listeners to these new cards as well.
const observeNewCalloutCards = (container: HTMLElement) => {
  const observer = new MutationObserver((mutationsList) => {
    mutationsList.forEach((mutation) => {
      if (mutation.type !== "childList") {
        return;
      }

      Array.from(mutation.addedNodes)
        .filter((node) => node instanceof HTMLElement)
        .forEach((newCard: HTMLElement) => {
          if (newCard.matches(CALLOUT_CARD_SELECTOR)) {
            setupCalloutCardTracking(newCard);
          }
        });
    });
  });

  observer.observe(container, { childList: true });
};
