import { DatePicker as CorDatePicker } from "@accor/ace-ui-core";

export const DATE_PICKER_READY_EVENT = 'date-picker-ready'
export default class DatePicker extends CorDatePicker {
  /** @inheritDoc */
  constructor(componentHost, componentName) {
    super(componentHost, componentName);
  }

  async initialize() {
    super.initialize();

    try {
      await this.waitForDatePickersRender();
      document.dispatchEvent(new CustomEvent(DATE_PICKER_READY_EVENT));
      this.changeLabelPosition();
    } catch (e) {
      console.warn(e);
    }
  }

  async waitForDatePickersRender() {
    await customElements.whenDefined('duet-date-picker');
    const datePickers = Array.from(this.componentHost.querySelectorAll('duet-date-picker'));

    if (!datePickers.length) {
      throw new Error("Missing duet-date-picker, aborting DOM changes");
    }

    const waitForDatePickerRender = (picker) => {
      return new Promise((resolve) => {
        if (picker.hasChildNodes()) {
          resolve();
          return;
        }

        const observer = new MutationObserver(() => {
          if (picker.hasChildNodes()) {
            observer.disconnect();
            resolve();
          }
        });

        observer.observe(picker, { childList: true, subtree: true });
      });
    };

    return Promise.all(datePickers.map(waitForDatePickerRender));
  }

  changeLabelPosition() {
    const checkInLabel = this.checkInData.querySelector(
      "label.ace-core-booking-engine__label",
    );
    const checkOutLabel = this.checkOutData.querySelector(
      "label.ace-core-booking-engine__label",
    );
    const hiddenCheckIn = this.checkInData.querySelector(
      'input[type="hidden"]',
    );
    const hiddenCheckOut = this.checkOutData.querySelector(
      'input[type="hidden"]',
    );
    const divCheckIn = document.createElement("div");
    const divCheckOut = document.createElement("div");
    divCheckIn.classList.add("check-in__container");
    divCheckOut.classList.add("check-out__container");
    const checkInDate =
      this.checkinDatePicker?.querySelector(".duet-date__input");
    const checkOutDate =
      this.checkoutDatePicker?.querySelector(".duet-date__input");

    checkInDate.parentNode.prepend(divCheckIn);
    divCheckIn.appendChild(checkInLabel);
    divCheckIn.appendChild(checkInDate);
    divCheckIn.appendChild(hiddenCheckIn);

    checkOutDate.parentNode.prepend(divCheckOut);
    divCheckOut.appendChild(checkOutLabel);
    divCheckOut.appendChild(checkOutDate);
    divCheckOut.appendChild(hiddenCheckOut);

    this.handleDateInputClick();
  }

  handleDateInputClick() {
    document.querySelectorAll(".duet-date__input").forEach((input) => {
      const datePicker = input.closest("duet-date-picker");
      if (!datePicker) return;

      const dialog = datePicker.querySelector(".duet-date__dialog");

      datePicker.addEventListener('duetClose', () => {
        dialog.removeAttribute("tabindex");
      })

      input.addEventListener("click", (event) => {
        if (datePicker && typeof datePicker.show === "function") {
          const monthSelectBox = datePicker.querySelector('.duet-date__select--month');
          const datePickerDialogAnimationDuration = 300;

          // On mobile when shown programmatically, the datePicker unexpectedly opens the month select.
          // We hack around by disabling then enabling it again after the dialog has rendered and focused.
          monthSelectBox.disabled = true;
          datePicker.show();
          dialog.setAttribute("tabindex", "-1");

          requestAnimationFrame(() => {
            setTimeout(() => {
              monthSelectBox.disabled = false;
              if (window.innerWidth < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
                // Focus the dialog on mobile where we can't input date manually.
                // On desktop, the focus will stay on the clicked input which is the expected behavior.
                dialog.focus();
              }
            }, datePickerDialogAnimationDuration);
          });
        }
      });
    });
  }
}
// Registering component in component factory.
CoreJS.BaseComponent.registerComponent(
  DatePicker.CLASS_NAMESPACE,
  DatePicker,
  true,
);
