import { TableContainer } from "@accor/ace-ui-core";

/** 
 * @override
 * This component uses CustomerAPI `getPlans`, `getLatamAuthUrlto` and `getContact` to display some "table Plan" that seems related to the user loyalty subscription.
 * As Fairmont does not use CustomerAPI, it would require a full rewrite (and actual specs) to adapt the logic to our usecase. 
 * In the meantime, we just want to avoid the current JS errors by overriding the class using its class namespace.
 */
export default class FairmontTableContainer {}

CoreJS.BaseComponent.registerComponent(
  TableContainer.CLASS_NAMESPACE,
  FairmontTableContainer,
  true,
);
