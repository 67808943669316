export default class StickyBookingEngineNavigation extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = "sticky-booking-engine__navigation";
  static BREAK_POINTS = CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg;

  constructor(componentHost, componentName) {
    super(componentHost, componentName);
  }

  initialize() {
    super.initialize();
    this.headerNavigationHooks();
    this.dataLayer = document
      .querySelector("[data-cmp-datalayer]")
      ?.getAttribute("data-cmp-datalayer");
    this.dataLayerjson = JSON.parse(this.dataLayer);
    document.addEventListener(
      "closeSubMenuDropdownPanelEvent",
      this.closeSubMenuDropdownPanelHanlder.bind(this),
    );
    document.addEventListener(
      CoreJS.DomEventConstants.DOM_CONTENT_LOADED,
      () => {
        this.calculateNavigationMargin();
      },
    );
    window.addEventListener(CoreJS.DomEventConstants.RESIZE, () => {
      this.calculateNavigationMargin();
    });
  }

  calculateNavigationMargin() {
    const navigationMarginLeft =
      this.componentHost.getBoundingClientRect().left * -1;

    const navListContainer = this.componentHost.querySelectorAll(
      ".link-navigation__panel",
    );
    navListContainer.forEach((nav) => {
      nav.style.marginLeft = `${navigationMarginLeft}px`;
    });
  }

  headerNavigationHooks() {
    this.navigationPanel = this.componentHost.querySelectorAll(
      ".link-navigation__panel",
    );
    this.btnSubMenu = this.componentHost.querySelectorAll(
      "button.link-navigation__menu__link:not(.sub-nav-container__menu__back button, .pos-container-geolocation__menu__back button, .pos-container-currency__menu__back button)",
    );

    this.subMenuBtnHandler();
  }
  subMenuBtnHandler() {
    this.btnSubMenu?.forEach((button) => {
      button.addEventListener(CoreJS.DomEventConstants.CLICK, (e) => {
        this.ariaControls = e.currentTarget?.getAttribute("aria-controls");
        if (
          e.currentTarget.classList.contains("activeBtn") &&
          this.componentHost
            ?.querySelector(`#${this.ariaControls}`)
            ?.classList.contains("showPanel")
        ) {
          this.closeSubMenuDropdownPanelHanlder();
        } else {
          const dataTracking = e.currentTarget.dataset.tracking;
          const position = e.currentTarget.parentElement.dataset.menuPosition;
          this.subMenuPosition = position;
          let moreBtnHeader;
          const datasEvent = {
            event: "GAevent",
            eventAction: "Menu - Click on CTA",
            eventCategory: "Navigation",
            eventLabel: `${dataTracking ? dataTracking : ""}`,
          };
          if (
            e.currentTarget?.classList?.contains("link-navigation__more-btn")
          ) {
            moreBtnHeader = "more";
          } else {
            moreBtnHeader = `${dataTracking ? dataTracking.toLowerCase() : ""}`;
          }
          const dataGA4Event = {
            event: "GA4event",
            eventName: "menu_interact",
            event_data: {
              pagename: `${this.dataLayerjson.pageName || ""}`,
              menu_position: `${position || ""}`,
              menu_label: moreBtnHeader,
            },
          };
          if (!this.isMercure && !this.isFairmont) {
            TagManager.trackEvent(datasEvent);
            window.dataLayer.push({
              event_data: null,
            });
            TagManager.trackEvent(dataGA4Event);
          }
          this.openSubMenuDropdownPanelHandler(e);
        }
      });
    });
  }

  openSubMenuDropdownPanelHandler(event) {
    // On pages where the sticky booking engine is only shown on scroll, the navigation does not have margins at page load.
    this.calculateNavigationMargin();
    this.closeSubMenuDropdownPanelHanlder();
    event.currentTarget.classList.add("activeBtn");
    event.currentTarget.setAttribute("aria-expanded", true);
    this.ariaControls = event.currentTarget?.getAttribute("aria-controls");
    this.componentHost
      .querySelector(`#${this.ariaControls}`)
      ?.classList.add("showPanel");
    this.componentHost
      .querySelector(`#${this.ariaControls}`)
      ?.setAttribute("aria-hidden", false);
    document.body.style.overflow = "hidden";
  }

  closeSubMenuDropdownPanelHanlder() {
    this.closeSubMoreMenuDropdownPanelHanlder();
    this.navigationPanel?.forEach((panel) => {
      if (panel?.classList.contains("showPanel")) {
        this.getId = panel.id;
        panel.classList?.remove("showPanel");
        panel.setAttribute("aria-hidden", true);

        this.getActiveBtn = this.componentHost.querySelector(
          `[aria-controls=${this.getId}]`,
        );
        if (this.getActiveBtn?.classList.contains("activeBtn")) {
          this.getActiveBtn?.classList.remove("activeBtn");
          this.getActiveBtn?.setAttribute("aria-expanded", false);
          this.getActiveBtn?.focus();
        }
      }
    });
    document.body.style.overflow = "auto";
  }

  closeSubMoreMenuDropdownPanelHanlder() {
    const secondaryPanel = this.secondaryPanel?.querySelectorAll(
      ".link-navigation__panel",
    );
    secondaryPanel?.forEach((panel) => {
      if (panel?.classList.contains("showMorePanel")) {
        this.getId = panel.id;
        panel.classList?.remove("showMorePanel");
        panel.setAttribute("aria-hidden", true);
        this.getActiveBtn = this.secondary.querySelector(
          `[aria-controls=${this.getId}]`,
        );
        if (this.getActiveBtn?.classList.contains("activeMoreBtn")) {
          this.getActiveBtn?.classList.remove("activeMoreBtn");
          this.getActiveBtn?.setAttribute("aria-expanded", false);
          this.getActiveBtn?.focus();
        }
      }
    });
  }
}

CoreJS.BaseComponent.registerComponent(
  StickyBookingEngineNavigation.CLASS_NAMESPACE,
  StickyBookingEngineNavigation,
);
