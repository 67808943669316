import { TagManager } from "@accor/ace-ui-core";
import bookingTracker  from "@booking-tracking";

export default class StickyBookingEngine extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = "sticky-booking-engine";

  /** @inheritDoc */
  constructor(componentHost, componentName) {
    super(componentHost, componentName);
  }

  /** @inheritDoc */
  initialize() {
    this.isEditorMode = document.querySelector('.aem-edit-mode');
    this.dialogs = [];

    this.mainWrapper = this.componentHost.querySelector(
      ".sticky-booking-engine-wrapper",
    );
    this.focusWrapper = this.componentHost.querySelector(".booking");
    this.stickyOverlay = this.mainWrapper.querySelector(".searching");
    this.datePickerOverlay = this.mainWrapper.querySelector(".booking");
    this.closeIcon = this.mainWrapper.querySelector(".close-icon");
    this.specialRate = this.componentHost.querySelector('.ace-core-booking-engine__options-trigger > button');
    this.bookingEngineOpenButton = this.datePickerOverlay.querySelector(
      ".ace-core-booking-engine__open--button",
    );
    this.bookingEngineCloseButton = this.datePickerOverlay.querySelector(
      ".ace-core-booking-engine__close--button",
    );
    this.logoScrollDown = document.querySelector(".booking__logo-scroll-down");
    this.logoScrollUp = document.querySelector(".booking__logo-scroll-up");
    this.stickyNavigation = document.querySelector(".sticky-booking-engine__navigation");
    this.mainWrapperParentElementIsVisible = false;
    this.navigationList = document.querySelector('.header-menu-product ul.link-navigation_nav_menu-list');
    this.stickyNavigation?.classList.add('sticky-booking-engine__navigation-brand');

    if(!this.navigationList) {
      this.navigationList = document.querySelector('ul.link-navigation_nav_menu-list');
      this.stickyNavigation?.classList.replace('sticky-booking-engine__navigation-brand','sticky-booking-engine__navigation-hotel');
    }
    if (this.mainWrapper.getAttribute("data-booking-open") !== "true") {
      this.mainWrapper.parentElement.classList.add("collapsed");
    }

    const focusable = this.focusWrapper.querySelectorAll("input, button");
    let visibleArray = [];

    this.immersiveHeading =
      this.componentHost.parentNode.querySelector(".immersive-heading");

    this.isShowAsSticky = this.mainWrapper?.dataset.showAsSticky === "true";

    focusable.forEach((element) => {
      const elementStyle = window.getComputedStyle(element);
      const isDisplayNone = elementStyle.display === "none";
      const isHidden = elementStyle.visibility === "hidden";

      if (!isDisplayNone && !isHidden) {
        visibleArray = [...visibleArray, element];
      }
    });

    this.stickyOverlay.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
      setTimeout(() => {
        focusable[0].focus();
      }, 10);
    });

    this.focusWrapper.addEventListener(
      CoreJS.DomEventConstants.KEY_DOWN,
      (e) => {

        if (!this.focusWrapper.classList.contains("active") && window.innerWidth >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
          return; 
        }

        //manage the navigation by tab and shift tab
        if (e.which === 9) {
          const firstFocusable = visibleArray[0];
          const lastFocusable = visibleArray[visibleArray.length - 1];
          const shift = e.shiftKey;

          if (shift) {
            if (document.activeElement === firstFocusable) {
              lastFocusable.focus();
              e.preventDefault();
            }
          } else {
            if (document.activeElement === lastFocusable) {
              firstFocusable.focus();
              e.preventDefault();
            }
          }
        }
        //manage the clocsing of booking engine by pressing escape
        if (e.which === 27) {
          this.closingBookingEngine();
        }
      },
    );

    this.stickyOverlay.addEventListener(
      CoreJS.DomEventConstants.CLICK,
      (event) => {
        event.preventDefault();
        this.mainWrapper.classList.add("opened");
        this.datePickerOverlay.classList.add("active");
        this.addSelectedState();
        this.bookingEngineCloseButton.parentElement.style.display = "flex";
        this.bookingEngineOpenButton.dispatchEvent(new Event("click"));

        bookingTracker.trackBookingOpen();
        this.preventIOSAutoZoomOnInputs();
      },
    );
    this.closeIcon.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
      this.closingBookingEngine();
    });

    this.bookingEngineCloseButton.addEventListener(
      CoreJS.DomEventConstants.CLICK,
      () => {
        this.datePickerOverlay.classList.remove("active");
        this.mainWrapper.classList.remove("opened");
        this.removeSelectedState();
        if (this.isShowAsSticky) {
          this.focusWrapper.classList.remove("navigation--position");
          this.createStickyOnHeaderMenu();
        }
        
        this.bookingEngineCloseButton.parentElement.style.display = "none";
        this.removeMaximumScaleViewportMeta();
      },
    );

    this.specialRate.addEventListener(CoreJS.DomEventConstants.CLICK, (event) => {
      this.componentHost.classList.contains("rates-open") ? this.componentHost.classList.remove("rates-open")
      :  this.componentHost.classList.add("rates-open");
      if (event?.target?.getAttribute("aria-expanded") == "true") {
        bookingTracker.trackFormInteract({form_action : "openadvanced",});
      }
    });

    // scroll event
    this.initScrollDetection();
 
    this.checkIfViewPortIsScroll();

    if (!this.componentHost.dataset.initialized) {
      this.checkImmersiveHeadingTotallyScrolled();
      this.componentHost.dataset.initialized = "true";
    }

    this.dialogs = [];

    window.addEventListener(CoreJS.DomEventConstants.DOM_CONTENT_LOADED, () => {
      this.dialogs = document.querySelectorAll('.ace-shared-acp-component, .ace-core-booking-engine__date-picker--wrapper, .ace-core-booking-engine__guests');
      this.initDetectHoverAndClickImmersive();
      this.initializeFormState();
      this.createStickyOnHeaderMenu();
    });

    
  }

  // Temporary hack to prevent Safari iOS autozoom.
  // The best approach would be to have the font-size at min 16px; but is not yet validated by design.
  // Recent Safari actually partially ignores maximum-scale=1, still allowing the user to zoom,
  // while not auto zooming the input, so the accessibility concern is mitigated
  preventIOSAutoZoomOnInputs() {
    if (/iPhone|iPad/.test(navigator.userAgent)) {
      const viewportMeta = document.querySelector('meta[name="viewport"]');
      if (viewportMeta) {
        viewportMeta.setAttribute('content', viewportMeta.getAttribute('content') + ', maximum-scale=1');
      }
    }
  }

  removeMaximumScaleViewportMeta() {
    const viewportMeta = document.querySelector('meta[name="viewport"]');
    const content = viewportMeta.getAttribute('content');
    viewportMeta.setAttribute('content', content.replace(", maximum-scale=1", ''));
  }

  clearState() {
    this.logoScrollDown?.classList.remove("active");
    this.logoScrollUp?.classList.remove("active");
    this.stickyNavigation?.classList.remove("active");
  }

  displayLogoUp() {
    this.logoScrollUp?.classList.add("active");
    this.stickyNavigation?.classList.add("active");
    this.logoScrollDown?.classList.remove("active");
  }
  displayLogoDown() {
    this.stickyNavigation?.classList.remove("active");
    this.logoScrollDown?.classList.add("active");
    this.logoScrollUp?.classList.remove("active"); 
  }

  initScrollDetection() {
    let lastScroll = 0;

    window.addEventListener(
      CoreJS.DomEventConstants.SCROLL,
      () => {
        this.checkIfViewPortIsScroll();
        this.checkImmersiveHeadingTotallyScrolled();

        const limitScroll = document.documentElement.scrollTop;

        if (this.mainWrapperParentElementIsVisible) {
          if (limitScroll > lastScroll) {
            this.displayLogoDown();
          } else {
            this.displayLogoUp();
          }
        } else {
          this.clearState();
        }

        lastScroll = limitScroll <= 0 ? 0 : limitScroll;
      },
      false,
    );
  }

  checkIfViewPortIsScroll() {
    const isMobileOrTablet = window.innerWidth < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg;
    if (window.innerHeight < window.scrollY) {
      if (!isMobileOrTablet) {
        this.mainWrapper.parentElement.classList.add("visible");
        this.onStickyOverlayVisible();
      }
      this.mainWrapper.parentElement.style.minHeight = null;
      this.mainWrapperParentElementIsVisible = true;
    } else {
      if (!isMobileOrTablet) {
        this.mainWrapper.parentElement.classList.remove("visible");
        this.onStickyOverlayHidden();
      }

      this.mainWrapperParentElementIsVisible = false;
      this.checkIfHotelExperiencePage();
    }
  }

  checkImmersiveHeadingTotallyScrolled() {
    const isMobileOrTablet = window.innerWidth < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg;
    if (!isMobileOrTablet) return;
    const stickyAfter = 88; // padding + button height

    if (this.immersiveHeading) {
      this.componentHost.classList.toggle("scrolled-past-immersive", window.scrollY - stickyAfter >= 0)
    }
  }

  checkIfHotelExperiencePage() {
    // if hotel-experience-detail-page
    if (
      window.innerWidth >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg &&
      document.body.classList.contains("hotel-experience-detail-page") &&
      document.querySelector(".heading-offer")
    ) {
      this.mainWrapper.parentElement.style.minHeight = `${document.querySelector(".heading-offer").offsetHeight}px`;
    }
  }
  closingBookingEngine() {
    this.stickyOverlay.classList.remove("hidden");
    this.datePickerOverlay.classList.remove("active");
    this.mainWrapper.classList.remove("opened");
    this.bookingEngineCloseButton.parentElement.style.display = "none";
    this.removeSelectedState();
    if (this.mainWrapper.getAttribute("data-booking-open") !== "true") {
      this.mainWrapper.parentElement.classList.add("collapsed");
    }
    this.stickyOverlay.focus();

    this.componentHost.setAttribute("aria-modal", false);
    document.documentElement.classList.remove("fixedbyModal");
    this.removeMaximumScaleViewportMeta();
  }

  isOpened() {
    return this.mainWrapper.classList.contains("opened");
  }

  isStickyOverlayVisible() {
    return this.componentHost.classList.contains('visible');
  }

  hasSelectedState() {
    return this.componentHost.classList.contains('selected')
  }

  onStickyOverlayVisible() {
    if (!this.isOpened()) {
      this.removeSelectedState();
    }
  }

  onStickyOverlayHidden() {
    if (this.shouldHaveSelectedState()) {
      this.addSelectedState();
    }
  }

  addSelectedState() {
    this.componentHost.classList.add('selected');
  }

  removeSelectedState() {
    this.componentHost.classList.remove('selected');
  }

  isErrorBlockVisible() {
    const errorBlocks = this.componentHost.querySelectorAll('.ace-core-booking-engine__error-block');
    return Array.from(errorBlocks).some(block => block.offsetParent !== null);
  };

  shouldHaveSelectedState() {
    const isDialogFocused = () => {
      const focusedElement = document.activeElement;
  
      return Array.from(this.dialogs).some((dialog) => {
        const hasDynamicChild = dialog.querySelector('.ahAutocomplete__result') !== null;
        const hasActiveChild = dialog.querySelector('.is-active, .show') !== null;
        const isFocusWithin = dialog.contains(focusedElement);
  
        return hasDynamicChild || hasActiveChild || isFocusWithin;
      }) || this.isErrorBlockVisible();
    };

    return this.componentHost.classList.contains('rates-open') || isDialogFocused();
  }

  initDetectHoverAndClickImmersive() {
    if (window.innerWidth <= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
      return;
    }

    this.componentHost.addEventListener(CoreJS.DomEventConstants.MOUSE_ENTER, () => {
      if (this.hasSelectedState() || this.isStickyOverlayVisible()) return;
      this.addSelectedState()
    });

    this.componentHost.addEventListener(CoreJS.DomEventConstants.MOUSE_LEAVE, () => {
      if (this.shouldHaveSelectedState() || this.isOpened()) return;
      this.removeSelectedState()
    });

    this.componentHost.addEventListener(CoreJS.DomEventConstants.FOCUS_IN, () => {
      if (this.hasSelectedState() || this.isStickyOverlayVisible()) return;
      this.addSelectedState()
    });

    this.componentHost.addEventListener(CoreJS.DomEventConstants.FOCUS_OUT, () => {
      if (this.shouldHaveSelectedState() || this.componentHost.contains(document.activeElement) || this.isOpened()) return;
      this.removeSelectedState()
    });

    this.componentHost.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
      if (this.hasSelectedState() || this.isStickyOverlayVisible()) return;
      this.addSelectedState()
    });

    document.addEventListener('click', (event) => {
      const isNavigationCheckesRates = (event.target.classList.contains("searching") || event.target.classList.contains("searching-text"));
      if(isNavigationCheckesRates) return;

      // Used to remove focus-within styles when clicking outside the room and guests button
      // A better approach would be to listen to the button blur event but the node is not created at load time.
      const handleRoomsAndGuestsButtonBlur = () => {
        const roomContainer = document.querySelector('.room-and-guests-label-input-icon-container');
        const button = roomContainer?.querySelector('button');

        if (button && !roomContainer.contains(event.target)) {
          button.disabled = true;
          setTimeout(() => (button.disabled = false), 100);
        }
      }

      handleRoomsAndGuestsButtonBlur();

      if (this.isOpened()) return;

      // Autocomplete results gets removed from the DOM when selected
      // So they are not excluded by the isOutsideClick condition `!this.componentHost.contains(event.target)`
      const isAutocompleteResult = [...event.target.classList].some(className =>
        className.includes('ahAutocomplete') || className.includes('hotel-result')
      );

      const isOutsideClick =
        !isAutocompleteResult &&
        !this.componentHost.contains(event.target) &&
        !Array.from(this.dialogs).some((dialog) => dialog.contains(event.target));

      if (isOutsideClick && !this.isErrorBlockVisible() && !this.componentHost.classList.contains("rates-open")) {
        this.removeSelectedState();
      }
    });
  }

  initializeFormState() {
    this.handleButtonState();
    this.handleDatePickers();
  }

  handleButtonState() {
    const guestsButton = this.componentHost.querySelector('.ace-core-booking-engine__guests button');

    if (!guestsButton) return;

    const defaultText = guestsButton.textContent.trim();

    const updateButtonState = () => {
      if (guestsButton.textContent.trim() === defaultText) {
        guestsButton.classList.remove('completed');
      } else {
        guestsButton.classList.add('completed');
      }
    };

    updateButtonState();

    const buttonObserver = new MutationObserver(updateButtonState);
    buttonObserver.observe(guestsButton, {
      characterData: true,
      childList: true,
      subtree: true,
    });
  }

  handleDatePickers() {
    const pickers = this.componentHost.querySelectorAll('duet-date-picker');
    let readyPickers = this.componentHost.querySelectorAll('duet-date-picker.hydrated .duet-date__input')?.length;

    if (!pickers) return;

    const mutationConfig = {
      childList: true,
      subtree: true,
    };

    pickers.forEach((picker) => {
      const observer = new MutationObserver(() => {
        readyPickers++;

        if (readyPickers === 2) {
          const pickersInputs = this.componentHost.querySelectorAll(
            'duet-date-picker.hydrated .duet-date__input',
          );
          pickersInputs.forEach((input) => input.classList.add('default'));
        }
      });
      observer.observe(picker, mutationConfig);

      const updatePickerState = () => {
        const input = picker.querySelector('.duet-date__input');
        if (input) {
          input.classList.remove('default');
        }
      };

      picker.addEventListener('duetChange', updatePickerState);
      picker.addEventListener('duetBlur', updatePickerState);
    });
  }
 
  createStickyOnHeaderMenu() {

    const isBtnPresent = document.querySelector('.link-navigation_nav_menu-list > li.btn__searching > .searching');
    
    if(!this.isShowAsSticky ||  isBtnPresent || window.innerWidth < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.xl) return;

    //create button checkRates
    const checkRatesButton = this.stickyOverlay.cloneNode(true);
    const nonImmersiveStyle = document
      .querySelector("input[name='menu-header-display-style']")
      ?.classList.contains("menu-header__non-immersive");
    if(nonImmersiveStyle){
      checkRatesButton.classList.add('btn--secondary');
    }else {
      checkRatesButton.classList.add('btn--primary');
    }

    //create new element li
    const liElement = document.createElement('li');
    liElement.classList.add('link-navigation__menu');
    liElement.classList.add('link-navigation__menuitem');
    liElement.classList.add('btn__searching');
    liElement.appendChild(checkRatesButton);

    checkRatesButton.addEventListener(
      CoreJS.DomEventConstants.CLICK,
      (event) => {
        event.preventDefault();
        this.mainWrapper.classList.add("opened");
        this.datePickerOverlay.classList.add("active");
        this.addSelectedState();
        this.bookingEngineCloseButton.parentElement.style.display = "flex";
        this.bookingEngineOpenButton.dispatchEvent(new Event("click"));
        liElement.remove();

        bookingTracker.trackBookingOpen();

        if (this.isShowAsSticky) {
          this.mainWrapper.parentElement.classList.add("visible");
          this.focusWrapper.classList.add("navigation--position");
        }
        this.preventIOSAutoZoomOnInputs();
      },
    );
    checkRatesButton.addEventListener(CoreJS.DomEventConstants.MOUSE_ENTER, () => {
      if(nonImmersiveStyle) return null;
      checkRatesButton.classList.replace("btn--primary", "btn--secondary");
    });
    checkRatesButton.addEventListener(CoreJS.DomEventConstants.MOUSE_LEAVE, () => {
      if(nonImmersiveStyle) return null;
      checkRatesButton.classList.replace("btn--secondary", "btn--primary");
    });

    this.navigationList.appendChild(liElement);
   
  }
}

// Registering component in component factory.
CoreJS.BaseComponent.registerComponent(
  StickyBookingEngine.CLASS_NAMESPACE,
  StickyBookingEngine,
);
