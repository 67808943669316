import {
  countries,
  languages,
  success,
  errors as i18nErrors,
} from "./newsletter.json";
import { pushEvent } from "../../../../utils/tracking";
import { isValidPhoneNumber, CountryCode } from "libphonenumber-js";

const API_URL = "/content/sling/servlets/ace/newsletter";

type Newsletter = {
  origin: string;
  codeSubscription: string;
};

async function isSubscribed(email: string) {
  const searchParams = new URLSearchParams(`email=${email}`);
  const res = await fetch(
    `${API_URL}/check-subscription?brand=fairmont&${searchParams.toString()}`,
  );

  if (!res.ok) {
    return false;
  }

  const newsletter = (await res.json()) as Newsletter;

  return newsletter.codeSubscription === "FRM";
}

function hideContent(host: HTMLElement) {
  const containers = [
    host.querySelector(
      ".ace-newsletter:not(.ace-newsletter--footer .ace-newsletter__description",
    ),
    host.querySelector(".ace-newsletter__input"),
    host.querySelector(".ace-modal-component"),
  ];

  containers.forEach((el) => el?.remove());
}

function displayUserAlreadySubscribed(host: HTMLElement, text: string) {
  const subscribedEl = document.createElement("div");
  subscribedEl.classList.add("ace-newsletter__subscribed");
  subscribedEl.innerHTML = `
     <div class="ace-newsletter__subscribed-icon"></div>
      <p class="ace-newsletter__subscribed-message">
      ${text} 
      </p>
  `;

  host?.appendChild(subscribedEl);
}

export function displayUserIsSubscribed(host: HTMLElement, text: string) {
  hideContent(host);
  displayUserAlreadySubscribed(host, text);
}

export async function handleNewsletterSubscription(
  host: HTMLElement,
  openModal: () => void,
) {
  const emailInput = host.querySelector("[name='mail']") as HTMLInputElement;
  if (!emailInput) {
    return;
  }

  const email = emailInput.value;
  const subscribed = await isSubscribed(email);

  if (!subscribed) {
    openModal();
    return;
  }
  displayUserIsSubscribed(
    host,
    success.alreadySubscribed[language] || success.alreadySubscribed.en,
  );

  const isFooter = !!host.closest("footer");
  pushEvent({
    eventName: "newsletter_interact",
    trackingData: {
      bloc_name: isFooter ? "newsletter footer" : "newsletter banner",
      bloc_interaction: "already subscribed",
    },
  });
}

function displayOptions(
  element: HTMLSelectElement,
  data: (string | string[])[],
) {
  element.innerHTML = data
    .map((value) => {
      const optionValue = typeof value === "object" ? value[0] : value;
      const optionDisplayedValue = typeof value === "object" ? value[1] : value;

      return `<option value="${optionValue}">${optionDisplayedValue}</option>`;
    })
    .join("");
}

export function displayCountryNameOptions(formEl: HTMLFormElement) {
  const countryNamesSelect = formEl.querySelector(
    "[name='country']",
  ) as HTMLSelectElement;
  if (!countryNamesSelect) {
    return;
  }

  displayOptions(
    countryNamesSelect,
    countries.map(({ name }) => name[language] || name.en),
  );
}

export function displayCountryCodeOptions(formEl: HTMLFormElement) {
  const countryCodesSelect = formEl.querySelector(
    "[name='country-code']",
  ) as HTMLSelectElement;
  if (!countryCodesSelect) {
    return;
  }

  displayOptions(
    countryCodesSelect,
    countries.map(({ countryCode, name, dialCode }) => [
      countryCode,
      `${name[language] || name.en} +${dialCode}`,
    ]),
  );
}

export function displayHonorificOptions(formEl: HTMLFormElement) {
  const honorifics = ["Mr", "Ms", "Mrs", "Dr", "Prof"];

  const honorificSelect = formEl.querySelector(
    "[name='title']",
  ) as HTMLSelectElement;

  if (!honorificSelect) {
    return;
  }

  displayOptions(honorificSelect, honorifics);
}
export function displayLanguageOptions(formEl: HTMLFormElement) {
  const languageOptions = languages[language] || languages.en;

  const languageSelect = formEl.querySelector(
    "[name='language']",
  ) as HTMLSelectElement;
  if (!languageSelect) {
    return;
  }

  displayOptions(languageSelect, languageOptions);
}

function displayUserIsSubscribedInModal(formEl: HTMLFormElement) {
  formEl.innerHTML = `
    <div class="ace-newsletter__subscribed-icon"></div>
    <div class="ace-newsletter__subscribed-container">
      <p class="ace-newsletter__subscribed-message">
        ${success.form[language] || success.form.en} 
      </p> 
      <a href="#" class="ace-newsletter__subscribed-link">${success.back[language] || success.back.en}</a>
    </div>
  `;
}

const language = document.documentElement.lang;

const errorMessages = {
  email: i18nErrors.email[language] || i18nErrors.email.en,
  "first-name": i18nErrors.firstName[language] || i18nErrors.firstName.en,
  "last-name": i18nErrors.lastName[language] || i18nErrors.lastName.en,
  "phone-number": i18nErrors.phoneNumber[language] || i18nErrors.phoneNumber.en,
};

type FormErrorItem = {
  [K in keyof typeof errorMessages]-?: { [P in K]: string };
}[keyof typeof errorMessages];

function checkInputValidity(
  formEl: HTMLElement,
  errors: FormErrorItem[],
  kind: keyof typeof errorMessages,
) {
  const element = formEl.querySelector(`[name='${kind}']`) as HTMLInputElement;

  if (!element) {
    throw new Error(`Element with name: ${kind} is undefined`);
  }

  if (!element.checkValidity()) {
    errors.push({ [kind]: errorMessages[kind] } as FormErrorItem);
    return "";
  }

  return element.value;
}

function checkPhoneNumberValidity(
  phoneNumber: string,
  countryCode: string,
  errors: FormErrorItem[],
) {
  if (!phoneNumber) {
    return phoneNumber;
  }

  if (
    !isValidPhoneNumber(phoneNumber, countryCode.toUpperCase() as CountryCode)
  ) {
    const key = "phone-number";
    errors.push({ [key]: errorMessages[key] });
    return "";
  }

  return phoneNumber;
}

function emptyElement(container: HTMLElement, selector: string) {
  const element = container.querySelector(selector);
  if (!element) {
    return;
  }
  element.innerHTML = "";
}

export function removeErrorsFromForm(formEl: HTMLFormElement) {
  formEl.querySelectorAll("input.error").forEach((el) => {
    el.classList.remove("error");
    el.setAttribute("aria-invalid", "false");
  });

  emptyElement(formEl, ".form-errors");
  emptyElement(formEl, ".form-server-errors");
}

function displayErrors(formEl: HTMLFormElement, errors: FormErrorItem[]) {
  const errorContainer = formEl.querySelector(".form-errors");

  if (!errorContainer) {
    return;
  }

  const errorIcon = document.createElement("div");
  errorIcon.classList.add("form-errors__icon");
  errorIcon.setAttribute("aria-hidden", "true");

  const errorMessages = document.createElement("div");
  errorMessages.classList.add("form-errors__error-messages");

  const errorSummary = document.createElement("div");
  errorSummary.classList.add("form-errors__error-messages__summary");
  errorSummary.textContent = (
    i18nErrors.summary[language] || i18nErrors.summary.en
  ).replace("{number}", errors.length);

  const errorList = document.createElement("ol");
  errorList.classList.add("form-errors__error-messages__error-list");

  errors.forEach((error) => {
    const keys = Object.keys(error);
    const key = keys[0];
    const input = formEl.querySelector(`[name='${key}']`);
    input?.classList?.add("error");
    input?.setAttribute("aria-invalid", "true");

    const errorItem = document.createElement("li");
    errorItem.id = input?.getAttribute("aria-describedby") ?? "";
    errorItem.classList.add("form-errors__error-messages__error-list__error");
    errorItem.textContent = error[key];
    errorList.append(errorItem);
  });

  errorMessages.append(errorSummary);
  errorMessages.append(errorList);

  errorContainer.append(errorIcon);
  errorContainer.append(errorMessages);

  pushEvent({
    eventName: "newsletter_interact",
    trackingData: {
      form_action: "error",
      error_type: "incorrect value",
      error_field: errors
        .map((error) => {
          const key = Object.keys(error)[0];
          return key === "email" ? key : key.replace("-", " ");
        })
        .join(","),
    },
  });
}

function displayServerErrors(formEl: HTMLFormElement) {
  const section = formEl.querySelector(".form-server-errors");

  const icon = document.createElement("div");
  icon.classList.add("form-server-errors__icon");
  icon.setAttribute("aria-hidden", "true");

  const p = document.createElement("p");
  p.classList.add("form-server-errors__message");
  p.textContent = i18nErrors.server[language] || i18nErrors.server.en;

  section?.append(icon);
  section?.append(p);
}

export async function subscribe(
  formEl: HTMLFormElement,
  closeModal: () => void,
) {
  try {
    removeErrorsFromForm(formEl);
    const errors = [];

    const email = checkInputValidity(formEl, errors, "email");
    const firstName = checkInputValidity(formEl, errors, "first-name");
    const lastName = checkInputValidity(formEl, errors, "last-name");

    const form = new FormData(formEl);
    const civility = form.get("title");
    const language = form.get("language");
    const country = form.get("country");
    const countryCode = form.get("country-code");
    const number = checkPhoneNumberValidity(
      form.get("phone-number") as string,
      countryCode as string,
      errors,
    );

    if (Object.keys(errors).length >= 1) {
      displayErrors(formEl, errors);
      return false;
    }

    const res = await fetch(`${API_URL}/subscribe`, {
      method: "post",
      body: JSON.stringify({
        brand: "fairmont",
        subscriber: {
          email,
          civility,
          firstName,
          lastName,
          language,
          country,
          phone: {
            countryCode,
            number,
          },
        },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!res.ok) {
      displayServerErrors(formEl);
      return false;
    }

    const subscription = (await res.json()) as Newsletter;
    if (subscription.codeSubscription !== "FRM") {
      return false;
    }

    displayUserIsSubscribedInModal(formEl);

    formEl
      .querySelector(".ace-newsletter__subscribed-link")
      ?.addEventListener("click", (e) => {
        e.preventDefault();
        closeModal();
      });

    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
}
