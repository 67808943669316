type UserData = {
  userId: string;
  firstName: string;
  lastName: string;
  city: string;
  country: string;
  email: string;
  newsletteroptin: string;
  loyaltyPoints: string;
  status: string;
  cardCodeTARS: string;
  cardNumber: string;
  currentNightsBalance: string;
};

const getUserDataFromStorage = () => {
  const apiUserDataJson = window.sessionStorage.getItem("userData");
  return apiUserDataJson ? (JSON.parse(apiUserDataJson) as UserData) : null;
};

export const getUserData = async () => {
  if (!document.querySelector('.ace-myaccount-component')) {
    return null;
  }

  return new Promise((resolve) => {
    document.addEventListener("my-account-loaded", () => {
      resolve(getUserDataFromStorage());
    });
  });
};
